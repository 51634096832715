.section {
  padding: 50px 0;
  margin: 100px 0;
  position: relative;
}

.container {
  padding: 0 15px;
  position: relative;
}
.container .textContainer .bigTitle {
  font-weight: bold;
  color: var(--text-big-title-color);
  position: relative;
  margin-bottom: 20px;
}
.container .textContainer .bigTitle::after {
  position: absolute;
  content: "";
  height: 3px;
  width: 32px;
  background-color: var(--primary);
  bottom: -10px;
  margin: 0 auto 0;
  left: 95px;
}
.container .textContainer .bigTitle::before {
  position: absolute;
  content: "";
  height: 3px;
  width: 80px;
  background-color: var(--primary-light);
  bottom: -10px;
  left: 0;
}
.container .textContainer .bigSubtitle {
  color: var(--text-big-subtitle-color);
  margin: 20px 0;
}

@media (max-width: 960px) {
  .section {
    margin: 50px 0;
  }
}
@media (max-width: 600px) {
  .section {
    margin: 25px 0;
  }
}
