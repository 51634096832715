.section {
  padding: 50px 0;
  margin: 100px 0;
  position: relative;
}
.section .container {
  padding: 0 15px;
  width: 70%;
  margin: 0 auto;
}
.section .container .itemsContainer {
  display: flex;
  flex-direction: column;
}
.section .container .itemsContainer .box {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0;
}
.section .container .itemsContainer .box:nth-child(2) {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row-reverse;
  margin: 20px 0;
}
.section .container .itemsContainer .box:nth-child(4) {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row-reverse;
  margin: 20px 0;
}
.section .container .itemsContainer .box .textContainer {
  width: 50%;
}
.section .container .itemsContainer .box .textContainer .title {
  position: relative;
  display: flex;
  align-items: center;
  margin: 20px 0;
}
.section .container .itemsContainer .box .textContainer .title img {
  position: relative;
  margin-right: 20px;
  width: 70px;
  padding: 5px;
  box-shadow: -7.25px 3.381px 18px 0px rgba(75, 81, 91, 0.08);
  border-radius: 50%;
  background-color: #ffffff;
}
.section .container .itemsContainer .box .textContainer .title:before {
  content: '';
  position: absolute;
  width: 60px;
  height: 60px;
  background-color: rgba(96, 145, 248, 0.1);
  border-radius: 50%;
  top: -15px;
  left: -15px;
  -webkit-animation: rotate-bl 5s linear infinite reverse forwards;
  animation: rotate-bl 5s linear infinite reverse forwards;
}

.section .container .itemsContainer .box .textContainer .title .line {
  width: 80px;
  height: 5px;
  background-color: var(--primary-light);
  position: absolute;
  bottom: -10px;
  left: 90px;
  border-radius: 10px;
}
.section .container .itemsContainer .box .textContainer .title:after {
  content: '';
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: var(--primary);
  box-shadow: 0 10px 30px 3px rgba(243, 167, 20, 0.6);
  border-radius: 50%;
  bottom: -12px;
  left: 90px;
  z-index: 1;
  -webkit-animation: slide-br 2s linear infinite alternate-reverse both;
  animation: slide-br 2s linear infinite alternate-reverse both;
}
.section .container .itemsContainer .box .textContainer .titleText {
  font-weight: bold;
  color: var(--secondary);
}

.section .container .itemsContainer .box .textContainer .subtitle2 {
  margin: 20px 0;
}
.section .container .itemsContainer .box .animationContainer {
  width: 50%;
  height: 300px;
  -webkit-animation: slide-top 5s linear infinite alternate-reverse both;
  animation: slide-top 5s linear infinite alternate-reverse both;
}

.section .container .itemsContainer .box .animationContainer img {
  width: 100%;
  height: 100%;
}

/* ----------------------------------------------
 * Generated by Animista on 2020-12-17 17:12:58
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation rotate-bl
 * ----------------------------------------
 */
@-webkit-keyframes rotate-bl {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: center right;
    transform-origin: center right;
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
    -webkit-transform-origin: center right;
    transform-origin: center right;
  }
}
@keyframes rotate-bl {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: center right;
    transform-origin: center right;
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
    -webkit-transform-origin: center right;
    transform-origin: center right;
  }
}

@-webkit-keyframes slide-br {
  0% {
    -webkit-transform: translateY(0) translateX(0);
    transform: translateY(0) translateX(0);
  }
  100% {
    -webkit-transform: translateY(0px) translateX(70px);
    transform: translateY(0px) translateX(70px);
  }
}
@keyframes slide-br {
  0% {
    -webkit-transform: translateY(0) translateX(0);
    transform: translateY(0) translateX(0);
  }
  100% {
    -webkit-transform: translateY(0px) translateX(70px);
    transform: translateY(0px) translateX(70px);
  }
}
/* ----------------------------------------------
 * Generated by Animista on 2020-12-18 12:58:51
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-top
 * ----------------------------------------
 */
@-webkit-keyframes slide-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-25px);
    transform: translateY(-25px);
  }
}
@keyframes slide-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-25px);
    transform: translateY(-25px);
  }
}

@media (max-width: 960px) {
  .section {
    margin: 0;
  }
  .section .container {
    width: 100%;
  }
}

@media (max-width: 600px) {
  .section .container .itemsContainer .box .textContainer .title img {
    width: 50px;
  }
  .section .container .itemsContainer .box .textContainer {
    width: 100%;
  }
  .section .container .itemsContainer .box .animationContainer {
    width: 100%;
    height: 200px;
    animation: Items_slide-top__2MejX 5s linear infinite alternate-reverse both;
    position: absolute;
    z-index: -1;
    opacity: 0.2;
  }

  .section .container .itemsContainer .box .textContainer .title .line {
    bottom: 0;
    left: 70px;
  }
  .section .container .itemsContainer .box .textContainer .title:after {
    bottom: -3px;
    left: 70px;
    -webkit-animation: slide-br 2s linear infinite alternate-reverse both;
    animation: slide-br 2s linear infinite alternate-reverse both;
  }
  .section .container .itemsContainer .box .textContainer .title:before {
    width: 40px;
    height: 40px;
    -webkit-animation: rotate-bl 5s linear infinite reverse forwards;
    animation: rotate-bl 5s linear infinite reverse forwards;
  }
}
