.section {
  padding: 50px 0;
  margin: 100px 0;
  position: relative;
}
.section .container {
  padding: 0 15px;
  width: 70%;
  margin: 0 auto;
}
.section .container .languageContainer {
  width: 120px;
}
.section .container .languageContainer .option {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  width: 100%;
}

.section .container .content .title {
  font-weight: bold;
  color: var(--text-big-title-color);
}
.section .container .content .disclaimerContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
}

@media (max-width: 960px) {
  .section {
    margin: 50px 0;
  }
  .section .container {
    width: 100%;
  }
}

@media (max-width: 600px) {
  .section {
    margin: 25px 0;
  }
}
