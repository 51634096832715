.section {
  position: relative;
  padding: 50px 0;
  margin: 100px 0;
  background: url('../../_res/background-contact.png');
  background-position: center center;
  background-repeat: no-repeat;
  overflow: hidden;
}
.section .container {
  padding: 0 15px;
  display: flex;
  justify-content: space-between;
  width: 70%;
  margin: 0 auto;
}

.section .container .textContainer {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
}
.container .textContainer .bigTitle {
  font-weight: bold;
  position: relative;
  margin-bottom: 20px;
}
.container .textContainer .bigTitle::after {
  position: absolute;
  content: '';
  height: 3px;
  width: 32px;
  background-color: var(--primary);
  bottom: -10px;
  margin: 0 auto 0;
  left: 95px;
}
.container .textContainer .bigTitle::before {
  position: absolute;
  content: '';
  height: 3px;
  width: 80px;
  background-color: var(--primary-light);
  bottom: -10px;
  left: 0;
}
.container .textContainer .bigSubtitle {
  margin: 20px 0;
}

.container .textContainer .contactItems .item {
  display: flex;
  align-items: center;
  align-content: center;
  font-weight: bold;
  margin: 5px 0;
}
.container .textContainer .contactItems .item h6 {
  font-weight: bold;
}
.container .textContainer .contactItems .item .iconContact {
  color: var(--primary);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.section .container .formContainer {
  width: 50%;
  border-top: 8px solid var(--orange);
  border-radius: 10px;
  padding: 10px 15px;
  background-color: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.section .container .formContainer .row {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
}

.section .container .formContainer .row .box50 {
  width: 48%;
}

.section .container .formContainer .row .box100 {
  width: 100%;
}

.submitContainer {
  display: flex;
  align-items: center;
}

.submitContainer .infoText {
  font-size: 0.6rem;
  padding: 0 5px;
}

.submitContainer .infoText a {
  text-decoration: none;
  color: inherit;
}

.success {
  background: var(--green);
  padding: 2px 10px;
  border-radius: 5px;
  text-align: center;
}
.error {
  background: var(--red);
  padding: 2px 10px;
  border-radius: 5px;
  text-align: center;
}
.grecaptcha-badge {
  z-index: 1;
}

@media (max-width: 1150px) {
  .section .container {
    width: 80%;
    gap: 1rem;
  }
}

@media (max-width: 960px) {
  .section {
    margin: 25px 0;
    padding: 25px 0;
  }

  .section .container {
    width: 100%;
    flex-direction: column;
    margin: 50px 0 20px 0;
  }

  .section .container .textContainer {
    width: 100%;
  }
  .section .container .formContainer {
    width: 100%;
  }
}
