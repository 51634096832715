.section {
  padding: 50px 0;
  margin: 50px 0;
  position: relative;
}

.section .shape {
  position: absolute;
  z-index: -1;
  left: 50px;
  top: -10%;
  width: 800px;
  height: 100%;
  transform: translate3d(-25%, 20%, 0) rotate(-30deg);
  background-image: linear-gradient(35deg, #fff, #edf4f8);
  border-radius: 120px;
}

.section .overlay {
  position: absolute;
  width: 100%;
  height: 100%;
}

.section .overlay .fig1 {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 24' fill='none'%3E%3Cpath d='M5.13608 0C3.53714 1.55543 2.26074 3.41003 1.37973 5.45794C0.498727 7.50584 0.0303797 9.70693 0.00142955 11.9355C-0.0275206 14.1641 0.383494 16.3766 1.21101 18.4466C2.03852 20.5167 3.26632 22.4037 4.82431 24L17 12.1556L5.13608 0Z' fill='%2385E2D4'%3E%3C/path%3E%3C/svg%3E");
  background-repeat: no-repeat;
  width: 40px;
  height: 40px;
  top: -5%;
  left: 10%;
  position: absolute;
  -webkit-animation: moveLeftBounce 3s linear infinite;
  -moz-animation: moveLeftBounce 3s linear infinite;
  -o-animation: moveLeftBounce 3s linear infinite;
  animation: moveLeftBounce 3s linear infinite;
}

@-webkit-keyframes moveLeftBounce {
  0% {
    -webkit-transform: translateX(0) skew(1, 2);
    transform: translateX(0) skew(1, 2);
  }
  50% {
    -webkit-transform: translateX(20px);
    transform: translateX(20px);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@-moz-keyframes moveLeftBounce {
  0% {
    -webkit-transform: translateX(0) skew(1, 2);
    transform: translateX(0) skew(1, 2);
  }
  50% {
    -webkit-transform: translateX(20px);
    transform: translateX(20px);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@-o-keyframes moveLeftBounce {
  0% {
    -webkit-transform: translateX(0) skew(1, 2);
    transform: translateX(0) skew(1, 2);
  }
  50% {
    -webkit-transform: translateX(20px);
    transform: translateX(20px);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}
@keyframes moveLeftBounce {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  50% {
    -webkit-transform: translateX(20px);
    transform: translateX(20px);
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

.section .overlay .fig2 {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='34' height='20' viewBox='0 0 34 20' fill='none'%3E%3Cpath d='M24 0L34 10L24 20L14 10L24 0Z' fill='%23FF6F42'%3E%3C/path%3E%3Cpath d='M10 0L20 10L10 20L0 10L10 0Z' fill='%23FDC3B1'%3E%3C/path%3E%3C/svg%3E");
  background-repeat: no-repeat;
  width: 40px;
  height: 40px;
  top: 5%;
  right: 10%;
  position: absolute;
  -webkit-animation: rotate3d 3s linear infinite;
  -moz-animation: rotate3d 3s linear infinite;
  -o-animation: rotate3d 3s linear infinite;
  animation: rotate3d 3s linear infinite;
}

@-webkit-keyframes rotate3d {
  0% {
    -webkit-transform: rotateY(0);
    transform: rotateY(0);
  }
  100% {
    -webkit-transform: rotateY(360deg);
    transform: rotateY(360deg);
  }
}
@-moz-keyframes rotate3d {
  0% {
    -webkit-transform: rotateY(0);
    transform: rotateY(0);
  }
  100% {
    -webkit-transform: rotateY(360deg);
    transform: rotateY(360deg);
  }
}
@-o-keyframes rotate3d {
  0% {
    -webkit-transform: rotateY(0);
    transform: rotateY(0);
  }
  100% {
    -webkit-transform: rotateY(360deg);
    transform: rotateY(360deg);
  }
}
@keyframes rotate3d {
  0% {
    -webkit-transform: rotateY(0);
    transform: rotateY(0);
  }
  100% {
    -webkit-transform: rotateY(360deg);
    transform: rotateY(360deg);
  }
}

.section .overlay .fig3 {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='48' height='48' viewBox='0 0 48 48' fill='none'%3E%3Cg clip-path='url(%23clip0)'%3E%3Crect width='24' height='48' rx='12' fill='%2309E3C2'%3E%3C/rect%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0'%3E%3Crect width='48' height='48' fill='white' transform='translate(0 -0.00012207)'%3E%3C/rect%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
  background-repeat: no-repeat;
  width: 40px;
  height: 50px;
  bottom: 40px;
  right: 10%;
  position: absolute;
  -webkit-animation: animationFramesOne 20s linear infinite;
  -moz-animation: animationFramesOne 20s linear infinite;
  -o-animation: animationFramesOne 20s linear infinite;
  animation: animationFramesOne 20s linear infinite;
}
@-webkit-keyframes animationFramesOne {
  0% {
    -webkit-transform: translate(0, 0) rotate(0);
    transform: translate(0, 0) rotate(0);
  }
  20% {
    -webkit-transform: translate(73px, -1px) rotate(36deg);
    transform: translate(73px, -1px) rotate(36deg);
  }
  40% {
    -webkit-transform: translate(141px, 72px) rotate(72deg);
    transform: translate(141px, 72px) rotate(72deg);
  }
  60% {
    -webkit-transform: translate(83px, 122px) rotate(108deg);
    transform: translate(83px, 122px) rotate(108deg);
  }
  80% {
    -webkit-transform: translate(-40px, 72px) rotate(144deg);
    transform: translate(-40px, 72px) rotate(144deg);
  }
  100% {
    -webkit-transform: translate(0, 0) rotate(0);
    transform: translate(0, 0) rotate(0);
  }
}
@-moz-keyframes animationFramesOne {
  0% {
    -webkit-transform: translate(0, 0) rotate(0);
    transform: translate(0, 0) rotate(0);
  }
  20% {
    -webkit-transform: translate(73px, -1px) rotate(36deg);
    transform: translate(73px, -1px) rotate(36deg);
  }
  40% {
    -webkit-transform: translate(141px, 72px) rotate(72deg);
    transform: translate(141px, 72px) rotate(72deg);
  }
  60% {
    -webkit-transform: translate(83px, 122px) rotate(108deg);
    transform: translate(83px, 122px) rotate(108deg);
  }
  80% {
    -webkit-transform: translate(-40px, 72px) rotate(144deg);
    transform: translate(-40px, 72px) rotate(144deg);
  }
  100% {
    -webkit-transform: translate(0, 0) rotate(0);
    transform: translate(0, 0) rotate(0);
  }
}
@-o-keyframes animationFramesOne {
  0% {
    -webkit-transform: translate(0, 0) rotate(0);
    transform: translate(0, 0) rotate(0);
  }
  20% {
    -webkit-transform: translate(73px, -1px) rotate(36deg);
    transform: translate(73px, -1px) rotate(36deg);
  }
  40% {
    -webkit-transform: translate(141px, 72px) rotate(72deg);
    transform: translate(141px, 72px) rotate(72deg);
  }
  60% {
    -webkit-transform: translate(83px, 122px) rotate(108deg);
    transform: translate(83px, 122px) rotate(108deg);
  }
  80% {
    -webkit-transform: translate(-40px, 72px) rotate(144deg);
    transform: translate(-40px, 72px) rotate(144deg);
  }
  100% {
    -webkit-transform: translate(0, 0) rotate(0);
    transform: translate(0, 0) rotate(0);
  }
}
@keyframes animationFramesOne {
  0% {
    -webkit-transform: translate(0, 0) rotate(0);
    transform: translate(0, 0) rotate(0);
  }
  20% {
    -webkit-transform: translate(73px, -1px) rotate(36deg);
    transform: translate(73px, -1px) rotate(36deg);
  }
  40% {
    -webkit-transform: translate(141px, 72px) rotate(72deg);
    transform: translate(141px, 72px) rotate(72deg);
  }
  60% {
    -webkit-transform: translate(83px, 122px) rotate(108deg);
    transform: translate(83px, 122px) rotate(108deg);
  }
  80% {
    -webkit-transform: translate(-40px, 72px) rotate(144deg);
    transform: translate(-40px, 72px) rotate(144deg);
  }
  100% {
    -webkit-transform: translate(0, 0) rotate(0);
    transform: translate(0, 0) rotate(0);
  }
}

.section .overlay .fig4 {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='48' height='48' viewBox='0 0 48 48' fill='none'%3E%3Cpath d='M0 0L24 24L0 48V0Z' fill='%23FDC3B1'%3E%3C/path%3E%3Cpath d='M24 0L48 24L24 48V0Z' fill='%23FF6F42'%3E%3C/path%3E%3C/svg%3E");
  background-repeat: no-repeat;
  width: 60px;
  height: 60px;
  bottom: 60px;
  left: 2%;
  position: absolute;
  -webkit-animation: animationFramesOne 20s linear infinite;
  -moz-animation: animationFramesOne 20s linear infinite;
  -o-animation: animationFramesOne 20s linear infinite;
  animation: animationFramesOne 20s linear infinite;
}

.section .container {
  width: 100%;
  margin: 0px auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-content: center;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
}

.customText {
  color: var(--primary);
  font-family: 'Aldrich, sans-serif';
}
.container .infoContainer {
}
.container .infoContainer .bigTitle {
  font-weight: bold;
  color: var(--secondary);
  position: relative;
  margin-bottom: 20px;
}

.container .infoContainer .bigTitle::after {
  position: absolute;
  content: '';
  height: 3px;
  width: 32px;
  background-color: var(--primary);
  bottom: -10px;
  margin: 0 auto 0;
  left: 95px;
}
.container .infoContainer .bigTitle::before {
  position: absolute;
  content: '';
  height: 3px;
  width: 80px;
  background-color: var(--primary-light);
  bottom: -10px;
  left: 0;
}

.container .infoContainer .bigSubtitle {
  margin: 20px 0;
}

.container .servicesContainer {
  position: relative;
  display: flex;
  flex-flow: wrap;
  align-content: center;
  justify-content: center;
}

.container .servicesContainer .link {
  width: 30%;
  display: flex;
  transition: all 0.3s ease-in;
  margin: 10px;
}

.container .servicesContainer .link:nth-child(1) {
  margin-bottom: -40px;
}
.container .servicesContainer .link:nth-child(2) {
  margin-top: -40px;
}
.container .servicesContainer .link:nth-child(3) {
  margin-top: 80px;
}
.container .servicesContainer .link:nth-child(3) {
  margin-bottom: -80px;
}

.container .servicesContainer .service {
  position: relative;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px 15px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.container .servicesContainer .service .iconContainer {
  margin-bottom: 20px;
  z-index: 1;
  transition: all 2s ease-in-out;
}
.container .servicesContainer .link .iconContainer::before {
  content: '';
  width: 90px;
  height: 160px;
  position: absolute;
  top: -20px;
  left: -20px;

  background: linear-gradient(
    20deg,
    var(--primary) 0%,
    var(--primary-light) 100%
  );
  z-index: -1;
  transform: rotate(20deg);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.container .servicesContainer .link .iconContainer img {
  width: 50px;
  height: 50px;
}

.container .servicesContainer .link .text {
  text-align: center;
  width: 100%;
  z-index: 2;
}

.container .servicesContainer .link .title {
  font-weight: bold;
  margin-bottom: 20px;
}

.container .servicesContainer .link .subtitle {
  margin-bottom: 20px;
}

@media (max-width: 1150px) {
  .container .servicesContainer .link {
    width: 40%;
  }
}

@media (max-width: 960px) {
  .section .container {
    /* width: 100%;
		flex-direction: column; */

    display: flex;
    flex-direction: column-reverse;
    /* grid-template-columns: 1fr; */
  }

  .section .container .infoContainer {
    width: 100%;
  }
  .section .container .servicesContainer {
    width: 100%;
  }

  .container .servicesContainer {
    flex-direction: column;
  }
  .container .servicesContainer .service {
    width: 100%;
    margin: 10px 0;
  }

  .container .servicesContainer .link {
    width: 100%;
    margin: 10px 0 !important;
  }

  .container .servicesContainer .link:hover {
    transform: scale(1);
  }
  .container .servicesContainer .link .subtitle {
    width: 50%;
    margin: 0 auto;
  }
}

@media (max-width: 600px) {
  .section {
    margin: 25px 0;
    padding: 25px 0;
  }
  .container .servicesContainer .link .iconContainer {
    position: absolute;
  }
  .container .servicesContainer .link .iconContainer img {
    width: 30px;
    height: 30px;
  }

  .container .servicesContainer .link .iconContainer::before {
    width: 70px;
    height: 80px;
    position: absolute;
    top: -40px;
    left: -30px;
  }
  .container .servicesContainer .link .subtitle {
    width: 100%;
    margin: 0 auto;
  }
}
