.rec.rec-item-wrapper:focus {
	outline: none;
}
.rec.rec-item-wrapper {
	height: 100%;
}

.elements-solutions-carousel .slick-track {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 1rem;
	height: 100%;
}

.elements-solutions-carousel .slick-slide {
	height: max-content;
}

.elements-solutions-carousel .slick-slide > div {
	height: 100%;
}

.slick-dots .slick-active div {
	background-color: rgba(103, 58, 183, 0.5) !important;
	box-shadow: rgb(103, 58, 183) 0px 0px 1px 3px !important;
}

.slick-dots li {
	margin: 0;
}
