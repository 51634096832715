.section {
  width: 60%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.section::before {
  content: '';
  position: absolute;
  width: 600px;
  height: 600px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' id='e1d6a9e2-5d0a-4358-9625-bb79dd06fec3' data-name='Layer 1' width='100%25' height='100%25' viewBox='0 0 615 585'%3E%3Cpath d='M408.97863,700.06688a293.53529,293.53529,0,1,1,207.55981-85.97449A291.6131,291.6131,0,0,1,408.97863,700.06688Zm0-584.82844c-160.61958,0-291.29359,130.67347-291.29359,291.29359s130.674,291.29413,291.29359,291.29413S700.27222,567.15215,700.27222,406.532,569.5982,115.23844,408.97863,115.23844Z' transform='translate(-102 -112.99772)' fill='rgba(142, 166, 197, 0.2)'/%3E%3Ccircle cx='487.35658' cy='63.86079' r='56.018' fill='rgba(243, 167, 20, 0.1)'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -webkit-animation-name: rotateme;
  animation-name: rotateme;
  -webkit-animation-duration: 20s;
  animation-duration: 20s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}

.section .container {
  width: 60%;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  position: relative;
}
.section .container .videoIcon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  display: none;
  visibility: hidden;
}

.section .container .videoIcon .iconPlay {
  cursor: pointer;
  width: 75px;
  height: 75px;
  line-height: 75px;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  position: relative;
  color: #0c5adb;
  -webkit-animation: ripple-blue 1s linear infinite;
  -moz-animation: ripple-blue 1s linear infinite;
  -o-animation: ripple-blue 1s linear infinite;
  animation: ripple-blue 1s linear infinite;
  transition: 0.5s;
  -webkit-transition: 0.5s;
  background: #fff;
  z-index: 44;
}

@-webkit-keyframes ripple-blue {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(0, 81, 255, 0.3),
      0 0 0 10px rgba(0, 81, 255, 0.3), 0 0 0 20px rgba(0, 81, 255, 0.3);
    box-shadow: 0 0 0 0 rgba(0, 81, 255, 0.3), 0 0 0 10px rgba(0, 81, 255, 0.3),
      0 0 0 20px rgba(0, 81, 255, 0.3);
  }
  100% {
    -webkit-box-shadow: 0 0 0 10px rgba(0, 81, 255, 0.3),
      0 0 0 20px rgba(0, 81, 255, 0.3), 0 0 0 30px rgba(0, 81, 255, 0);
    box-shadow: 0 0 0 10px rgba(0, 81, 255, 0.3),
      0 0 0 20px rgba(0, 81, 255, 0.3), 0 0 0 30px rgba(0, 81, 255, 0);
  }
}
@-moz-keyframes ripple-blue {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(0, 81, 255, 0.3),
      0 0 0 10px rgba(0, 81, 255, 0.3), 0 0 0 20px rgba(0, 81, 255, 0.3);
    box-shadow: 0 0 0 0 rgba(0, 81, 255, 0.3), 0 0 0 10px rgba(0, 81, 255, 0.3),
      0 0 0 20px rgba(0, 81, 255, 0.3);
  }
  100% {
    -webkit-box-shadow: 0 0 0 10px rgba(0, 81, 255, 0.3),
      0 0 0 20px rgba(0, 81, 255, 0.3), 0 0 0 30px rgba(0, 81, 255, 0);
    box-shadow: 0 0 0 10px rgba(0, 81, 255, 0.3),
      0 0 0 20px rgba(0, 81, 255, 0.3), 0 0 0 30px rgba(0, 81, 255, 0);
  }
}
@-o-keyframes ripple-blue {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(0, 81, 255, 0.3),
      0 0 0 10px rgba(0, 81, 255, 0.3), 0 0 0 20px rgba(0, 81, 255, 0.3);
    box-shadow: 0 0 0 0 rgba(0, 81, 255, 0.3), 0 0 0 10px rgba(0, 81, 255, 0.3),
      0 0 0 20px rgba(0, 81, 255, 0.3);
  }
  100% {
    -webkit-box-shadow: 0 0 0 10px rgba(0, 81, 255, 0.3),
      0 0 0 20px rgba(0, 81, 255, 0.3), 0 0 0 30px rgba(0, 81, 255, 0);
    box-shadow: 0 0 0 10px rgba(0, 81, 255, 0.3),
      0 0 0 20px rgba(0, 81, 255, 0.3), 0 0 0 30px rgba(0, 81, 255, 0);
  }
}
@keyframes ripple-blue {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(0, 81, 255, 0.3),
      0 0 0 10px rgba(0, 81, 255, 0.3), 0 0 0 20px rgba(0, 81, 255, 0.3);
    box-shadow: 0 0 0 0 rgba(0, 81, 255, 0.3), 0 0 0 10px rgba(0, 81, 255, 0.3),
      0 0 0 20px rgba(0, 81, 255, 0.3);
  }
  100% {
    -webkit-box-shadow: 0 0 0 10px rgba(0, 81, 255, 0.3),
      0 0 0 20px rgba(0, 81, 255, 0.3), 0 0 0 30px rgba(0, 81, 255, 0);
    box-shadow: 0 0 0 10px rgba(0, 81, 255, 0.3),
      0 0 0 20px rgba(0, 81, 255, 0.3), 0 0 0 30px rgba(0, 81, 255, 0);
  }
}

.playerWrapper {
  width: 100%;
  position: relative;
  padding-top: 56.25%; /* 720 / 1280 = 0.5625 */
}

.videoWrapper {
  position: absolute;
  top: 0;
  left: 0;
}
@keyframes rotateme {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes rotateme {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

video:hover,
video:focus {
  outline: 0;
}

::-webkit-media-controls {
  background-color: transparent;
}
video::-webkit-media-controls-panel {
  width: 40px;
}

/* MEDIA QUERIES */

@media (max-width: 960px) {
  .section {
    width: 100%;
    height: 100%;
    position: relative;
    display: block;
  }
  .section::before {
    content: none;
  }
  .section .container {
    width: 60%;
    margin: 50px auto;
  }
}

@media (max-width: 490px) {
  .section .container {
    width: 95%;
  }
}
