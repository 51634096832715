.section {
	position: relative;
	padding: 50px 0;
}
.section .container {
	padding: 0 15px;
}

.customText {
	color: var(--primary-color);
	font-family: 'Aldrich, sans-serif';
}
.section .container .infoContainer {
	width: 70%;
	margin: 50px auto;
	display: flex;
	align-items: center;
}
.container .infoContainer .bigTitle {
	font-weight: bold;
	color: var(--secondary-color);
	position: relative;
	margin-bottom: 20px;
}
.container .infoContainer .bigTitle::after {
	position: absolute;
	content: '';
	height: 3px;
	width: 32px;
	background-color: var(--primary-color);
	bottom: -10px;
	margin: 0 auto 0;
	left: 95px;
}
.container .infoContainer .bigTitle::before {
	position: absolute;
	content: '';
	height: 3px;
	width: 80px;
	background-color: var(--primary-color-light);
	bottom: -10px;
	left: 0;
}
.container .infoContainer .bigSubtitle {
	margin: 20px 0;
}

.container .infoContainer .textContainer {
	width: 50%;
}

.container .benefitsContainer {
	display: flex;
	justify-content: space-between;
}
.container .benefitsContainer .benefit {
	position: relative;
	overflow: hidden;
	width: 22%;
	display: flex;
	flex-direction: column;
	text-align: center;
	padding: 20px 15px;
	color: #ffffff;
	border-radius: 10px;
	box-shadow: 5px 4px 40px rgba(0, 0, 0, 0.2);
	-webkit-box-shadow: 5px 4px 40px rgba(0, 0, 0, 0.2);
}

.container .benefitsContainer .benefit:nth-child(1) {
	background-color: var(--orange);
}
.container .benefitsContainer .benefit:nth-child(2) {
	background-color: var(--green);
}
.container .benefitsContainer .benefit:nth-child(3) {
	background-color: var(--blue);
}
.container .benefitsContainer .benefit:nth-child(4) {
	background-color: var(--purple);
}

.container .benefitsContainer .benefit .title {
	font-weight: bold;
}

.container .benefitsContainer .benefit:before {
	content: '';
	position: absolute;
	display: block;
	width: 100%;
	height: 100%;
	border: 2px solid #ffffff;
	top: 10px;
	left: 10px;
	right: 10px;
	bottom: 10px;
	border-radius: 10px;
}

.container .benefitsContainer .benefit .iconContainer {
	position: absolute;
	bottom: -30px;
	right: -30px;
	opacity: 0.3;
	transform: rotate(45deg);
}

/* MEDIA QUERIES */

@media (max-width: 960px) {
	.container .infoContainer {
		width: 100%;
		flex-direction: column;
	}
	.container .infoContainer .textContainer {
		width: 100%;
	}

	.container .benefitsContainer {
		flex-direction: column;
	}
	.container .benefitsContainer .benefit {
		width: 100%;
		margin-bottom: 10px;
		box-shadow: 1px 4px 10px rgba(0, 0, 0, 0.2);
		-webkit-box-shadow: 1px 4px 10px rgba(0, 0, 0, 0.2);
	}
}

@media (max-width: 960px) {
	.section {
		margin: 25px 0;
		padding: 25px 0;
	}
}
