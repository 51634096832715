.container {
  width: 100%;
  padding: 0 15px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: center;
  /* align-items: center; */
  overflow: hidden;
}

.container .detailsContainer {
  display: flex;
}

.container .title {
  background: linear-gradient(
    90deg,
    var(--text-primary-color) 0%,
    var(--text-secondary-color) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
  font-size: clamp(1.9rem, 0.7285714285714286rem + 5.857vw, 6rem);
}

.container .subtitle {
  margin: 50px 0;
}

.container .wordsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  z-index: 2;
  align-items: center;
  align-content: flex-start;
}
.container .wordsContainer .word {
  padding: 0px 25px;
  border-radius: 25px;
  margin: 5px;
  color: #f3f3f3;
  height: auto;
  text-align: center;
}

@media (max-width: 960px) {
  .container {
    position: relative;
    width: 100%;
    padding: 0 15px;
    margin-top: 100px;
  }
}
