.section {
  position: relative;
  padding: 50px 0;
  margin: 100px 0;
}

.section .container {
  position: relative;
  width: 70%;
  margin: 0 auto;
  padding: 0px 15px;
  z-index: 2;
}

.section .container .textContainer {
  text-align: center;
}

.section .container .textContainer .title {
  font-weight: bold;
}

.section .container .questionsContainer {
  display: flex;
  justify-content: space-between;
  flex-flow: wrap;
  margin: 50px 0;
}

.section .container .questionsContainer .question {
  display: flex;
  align-items: flex-start;
  width: 40%;
  margin: 20px 0;
}

.section .container .questionsContainer .question .iconContainer {
  font-weight: bold;
  color: #ffffff;
  padding: 10px 17px;
  border-radius: 50%;
  background-color: var(--primary);
  margin-right: 20px;
}

@media (max-width: 960px) {
  .section {
    margin: 50px 0;
  }
  .section .container {
    width: 100%;
  }
}

@media (max-width: 600px) {
  .section {
    margin: 25px 0;
    padding: 25px 0;
  }
  .section .container .questionsContainer {
    flex-direction: column;
  }
  .section .container .questionsContainer .question {
    width: 100%;
    margin: 5px 0;
  }
}
