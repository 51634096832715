.container {
  width: 70%;
  margin: 0 auto;
  padding: 0 15px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: center;
  /* align-items: center; */
  overflow: hidden;
}

.container .title {
  background: linear-gradient(
    90deg,
    var(--text-primary-color) 0%,
    var(--grey) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
  font-size: clamp(1.9rem, 0.7285714285714286rem + 5.857vw, 6rem);
}

.container .subtitle {
  margin: 50px 0;
}

@media (max-width: 960px) {
  .container {
    width: 100%;
    padding: 0;
    margin-top: 100px;
  }
}
