.section {
  position: relative;
  padding: 50px 0;
  margin: 50px 0;
}

.section .container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 70%;
  padding: 0;
  margin: 0 auto;
}

.section .container .imageContainer {
  position: absolute;
  top: -50px;
  left: 45%;
  transform: translate(-50%, -50%);
  width: 100px;
  height: 100px;
  background: #f7f7f7;
  padding: 0px;
  border: 5px solid var(--border-purple);
  border-radius: 50%;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  animation-name: float-bob;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  -moz-animation-name: float-bob;
  -moz-animation-duration: 2s;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
  -ms-animation-name: float-bob;
  -ms-animation-duration: 2s;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: linear;
  -o-animation-name: float-bob;
  -o-animation-duration: 2s;
  -o-animation-iteration-count: infinite;
  -o-animation-timing-function: linear;
}

.section .container .imageContainer img {
  padding: 5px;
  border: 5px solid rgb(255 255 255);
  border-radius: 50%;
  background: var(--border-purple);
  width: 100%;
}

.section .container .textContainer {
  position: absolute;
  top: -100px;
  left: 50%;
  transform: translate(-50%, 0);
}

.section .container .textContainer .text {
  color: var(--border-purple);
  font-weight: bold !important;
  text-align: center;
  letter-spacing: 3px;
}

.bounce-animate {
  animation-name: float-bob;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  -moz-animation-name: float-bob;
  -moz-animation-duration: 2s;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
  -ms-animation-name: float-bob;
  -ms-animation-duration: 2s;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: linear;
  -o-animation-name: float-bob;
  -o-animation-duration: 2s;
  -o-animation-iteration-count: infinite;
  -o-animation-timing-function: linear;
}

@keyframes float-bob {
  0% {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  50% {
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px);
  }
  100% {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }
}

@-webkit-keyframes float-bob {
  0% {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  50% {
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px);
  }
  100% {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }
}

@media (max-width: 960px) {
  .section .container {
    width: 100%;
  }
}

@media (max-width: 600px) {
  .section {
    margin: 25px 0;
    padding: 25px 0;
  }
}
