.customForm {
}
.customAlert {
  margin: 10px 0;
}

.customList {
  list-style-type: none;
}
.generatePanel {
  margin: 10px;
  display: flex;
  justify-content: space-between;
}
.row {
  margin: 20px 0;
}
.generatePanel {
  padding: 10px;
}
.generatePanel .customForm {
  width: 45%;
}
.generatePanel .codeContainer {
  width: 45%;
}
.generatePanel .codeContainer .code {
  max-height: 200px;
  border-radius: 5px;
  padding: 5px;
  margin: 10px 0;
  border: 1px solid rgb(133, 133, 133);
  overflow: hidden;
  overflow-y: auto;
}

.visualPanel {
  padding: 0 20px;
}
