.section {
  position: relative;
  padding: 50px 0;
  margin: 50px 0;
  margin-bottom: 100px;
}

.section .container {
  position: relative;
  width: 70%;
  margin: 0 auto;
  padding: 0px 15px;
  z-index: 2;
}

.lastNewsSection {
  display: flex;
  flex-direction: column;
}
.lastNewsSection .textContainer {
  width: 100%;
  padding: 0;
}
.lastNewsSection .textContainer .bigTitle {
  font-weight: bold;
  position: relative;
  margin-bottom: 20px;
}

.lastNewsSection .textContainer .bigTitle::after {
  position: absolute;
  content: "";
  height: 3px;
  width: 32px;
  background-color: var(--primary);
  bottom: -10px;
  margin: 0 auto 0;
  left: 95px;
}
.lastNewsSection .textContainer .bigTitle::before {
  position: absolute;
  content: "";
  height: 3px;
  width: 80px;
  background-color: var(--primary-light);
  bottom: -10px;
  left: 0;
}
.lastNewsSection .followContainer {
  margin: 20px 0;
  padding: 20px 10px;
  display: flex;
  align-items: center;
  border-radius: 10px;
  flex-flow: wrap;
  justify-content: space-between;
  background: rgb(70, 163, 242);
  background: linear-gradient(
    135deg,
    rgba(70, 163, 242, 1) 0%,
    rgba(44, 122, 231, 1) 22%,
    rgba(70, 163, 242, 1) 50%,
    rgba(0, 59, 115, 1) 100%
  );
}
.lastNewsSection .followContainer .text {
  font-weight: bold;
  color: #ffffff;
}
.lastNewsSection .followContainer .followSocial {
  background: #ffffff;
  padding: 11px 10px 5px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 50px;
  box-shadow: inset 0px 0 5px 3px rgb(0 0 0 / 30%);
}

.lastNewsSection .followContainer .followSocial a .socialIcon {
  color: var(--secondary);
  margin: 0 5px;
  transition: all 0.2 ease-in;
}

.lastNewsSection .followContainer .followSocial a:hover .socialIcon {
  color: var(--secondary-dark);
}

.lastNewsContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.lastNewsContainer .lastNews {
  border-radius: 10px;
  background-color: #ffffff;
  position: relative;
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;
}
.lastNewsContainer .lastNews .infoContainer {
  padding: 10px 0;
}
.lastNewsContainer .lastNews .imageContainer {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  height: 150px;
  margin: 0 auto;
  border-radius: 10px 10px 0 0;
  overflow: hidden;
  background-size: cover !important;
  background-repeat: no-repeat;
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

.lastNewsContainer .lastNews .imageContainer img {
  max-width: 100%;
  height: 100%;
}


.lastNewsContainer .dateContainer {
  position: absolute;
  bottom: 0px;
  left: 0;
  background-color: #ffffff;
  border-radius: 0 10px 0 0;
  padding: 10px;
}

.lastNewsContainer .dateContainer .date {
  color: var(--secondary-dark);
  font-weight: bold;
}

.lastNewsContainer .infoContainer .icon {
  color: var(--secondary);
}

.lastNewsContainer .location {
  text-align: center;
  color: var(--text-small-item);
  display: flex;
  align-items: center;
  align-self: start;
}
.lastNewsContainer .title {
  margin: 10px 0;
  cursor: pointer;
  color: var(--secondary-dark);
  font-weight: bold;
}

.lastNewsContainer .title a {
  color: inherit;
  text-decoration: none;
}

@media (max-width: 960px) {
  .section .container {
    width: 100%;
  }

  .lastNewsSection {
    flex-direction: column;
  }

  .lastNewsSection .textContainer {
    padding: 0;
  }

  .lastNewsContainer {
    width: 100%;
  }
}

@media (max-width: 600px) {
  .section {
    margin: 25px 0;
    padding: 25px 0;
  }
  .lastNewsSection .textContainer {
    width: 100%;
    padding: 0;
  }
  .lastNewsSection .followContainer {
    margin: 20px 0;
    padding: 10px 5px;
  }
  .lastNewsContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .lastNewsContainer .lastNews {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin: 10px 0;
  }
  .lastNewsContainer .lastNews .imageContainer {
    max-height: 100px;
    height: 100px;
    width: 40%;
    position: relative;
  }
  .lastNewsContainer .lastNews:hover .imageContainer {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
  .lastNewsContainer .lastNews .infoContainer {
    width: 60%;
    padding: 10px;
  }
}
