.section {
	position: relative;
	padding: 50px 0;
	margin: 100px 0;
}

.overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-image: url('../../../_res/decor-solutions.png');
	background-repeat: no-repeat;
	background-position: bottom center;
	background-size: 100% 100%;
	opacity: 0.1;
	pointer-events: none;
}

.section .container {
	position: relative;
	width: 100%;
	margin: 0 auto;
	padding: 0px 15px;
	z-index: 2;
	display: flex;
}

.section .container .elementsContainer {
	width: 100%;
	position: relative;
	margin: 20px 0;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}
/* 
.section .container .elementsContainer :global(.slick-slide > div) { */
:global(.slick-slide > div) {
	display: flex;
	justify-content: center;
}

.section .container .elementsContainer :global(.slick-slide > div) > .element {
	height: 650px;
}
.section .container .elementsContainer .element {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: clamp(300px, 70%, 430px);
	height: auto;
	margin: 20px 0;
	padding: 20px 35px;
	border-radius: 40px;
	background: #ffffff;
	box-shadow: 0 0 15px rgba(51, 51, 51, 0.1);
}
.section .container .elementsContainer .element:focus {
	outline: none;
}
.section .container .elementsContainer .element .title {
	margin: 20px 0;
	position: relative;
	font-weight: bold;
	text-align: center;
}
.section .container .elementsContainer .element .subtitle {
	position: relative;
	margin: 20px 0;
}
.section .container .elementsContainer .element .title::after {
	content: '';
	position: absolute;
	bottom: -15px;
	left: 50%;
	transform: translateX(-50%);

	width: 80px;
	height: 5px;
	background-color: var(--secondary);
}

.section .container .elementsContainer .element img {
	width: 70px;
	height: 70px;
	position: relative;
	z-index: 1;
}
.section .container .elementsContainer .element .iconContainer {
	position: relative;
	width: 100%;
	height: 150px;
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;
}

.section .container .elementsContainer .element .figuresContainer {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 80px;
	height: 80px;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	-webkit-transform-origin: center center;
	-moz-transform-origin: center center;
	-ms-transform-origin: center center;
	-o-transform-origin: center center;
	transform-origin: center center;
	-webkit-animation: service_hexagon 8s infinite linear;
	-moz-animation: service_hexagon 8s infinite linear;
	-o-animation: service_hexagon 8s infinite linear;
	animation: service_hexagon 8s infinite linear;
}
.section .container .elementsContainer .element .figuresContainer .fig1 {
	position: absolute;
	left: -25px;
	top: 60px;
	width: 20px;
	height: 20px;
	border-radius: 50%;
	background: var(--mov-light);
}
.section .container .elementsContainer .element .figuresContainer .fig2 {
	position: absolute;
	left: 95px;
	top: 30px;
	width: 20px;
	height: 20px;
	border-radius: 50%;
	background: var(--green);
}
.section .container .elementsContainer .element .figuresContainer .fig3 {
	position: absolute;
	top: -20px;
	left: 55px;
	width: 12px;
	height: 12px;
	border-radius: 50%;
	background: rgba(99, 119, 238, 0.8);
}

@media (max-width: 960px) {
	.section .container {
		width: 100%;
	}
}

@media (max-width: 600px) {
	.section {
		padding: 25px 0;
		margin: 25px 0;
	}
	.section .container .elementsContainer .element {
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
		height: auto;
		margin: 20px 0;
		padding: 10px 15px;
		border-radius: none;
		background: transparent;
		box-shadow: none;
	}
	.section .container .elementsContainer .element .subtitle {
		text-indent: 20px;
	}

	.section .container .elementsContainer .element .iconContainer {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		opacity: 0.2;
	}
}

@-webkit-keyframes service_hexagon {
	0% {
		-webkit-transform: translate(-50%, -50%) rotate(0deg);
		-moz-transform: translate(-50%, -50%) rotate(0deg);
		-o-transform: translate(-50%, -50%) rotate(0deg);
		transform: translate(-50%, -50%) rotate(0deg);
	}

	100% {
		-webkit-transform: translate(-50%, -50%) rotate(360deg);
		-moz-transform: translate(-50%, -50%) rotate(360deg);
		-o-transform: translate(-50%, -50%) rotate(360deg);
		transform: translate(-50%, -50%) rotate(360deg);
	}
}
@keyframes service_hexagon {
	0% {
		-webkit-transform: translate(-50%, -50%) rotate(0deg);
		-moz-transform: translate(-50%, -50%) rotate(0deg);
		-o-transform: translate(-50%, -50%) rotate(0deg);
		transform: translate(-50%, -50%) rotate(0deg);
	}

	100% {
		-webkit-transform: translate(-50%, -50%) rotate(360deg);
		-moz-transform: translate(-50%, -50%) rotate(360deg);
		-o-transform: translate(-50%, -50%) rotate(360deg);
		transform: translate(-50%, -50%) rotate(360deg);
	}
}

.carouselArrow {
	box-sizing: border-box;
	transition: all 0.3s ease 0s;
	font-size: 1.6em;
	background-color: rgba(103, 58, 183, 0.1);
	color: rgb(153, 153, 153);
	box-shadow: rgb(51, 51, 51) 0px 0px 2px 0px;
	border-radius: 50%;
	border: medium none;
	padding: 0px;
	width: 50px;
	height: 50px;
	min-width: 50px;
	line-height: 50px;
	align-self: center;
	cursor: pointer;
	outline: none;
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	z-index: 1;
}

.carouselArrow:hover {
	color: rgb(255, 255, 255);
	background-color: rgb(103, 58, 183);
	box-shadow: rgb(51, 51, 51) 0px 0px 2px 0px;
}

.leftArrow {
	left: 10px;
}

.rightArrow {
	right: 10px;
}

.testContainer {
	display: flex;
	align-items: stretch;
	justify-content: space-between;
	gap: 1rem;
}

.testContainer .testEl {
	border: 1px solid;
	background: red;
	width: 30%;
}
