.section {
  position: relative;
  margin: 0;
  height: 100vh;
  overflow: hidden;
}

.section .container {
  position: relative;
  display: flex;
  align-items: center;
  flex-flow: wrap;
  padding: 0;
  justify-content: space-between;
  height: 100%;
}

.overlay {
  position: absolute;
  width: 40%;
  height: 200%;
  display: flex;
  justify-content: space-around;
}

.overlay .bar {
  transform: rotate(-15deg);
  width: 70px;
  height: 100%;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(242, 242, 242, 1) 50%,
    rgba(255, 255, 255, 0) 100%
  );
}

.shapesContainer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: none;
}
.shapesContainer .shape1 {
  position: absolute;
  box-shadow: -45px 85px 85px 5px rgba(24, 23, 21, 0.03);
  border-radius: 65px;
  -webkit-transform: rotate(-35deg);
  transform: rotate(-35deg);
  width: 130px;
  height: 800px;
  top: 10%;
  left: 0;
}
.shapesContainer .shape2 {
  position: absolute;
  box-shadow: -45px 85px 85px 5px rgba(24, 23, 21, 0.03);
  border-radius: 65px;
  -webkit-transform: rotate(-35deg);
  transform: rotate(-35deg);
  width: 130px;
  height: 800px;
  top: 20%;
  left: 16%;
}
.shapesContainer .shape3 {
  position: absolute;
  box-shadow: -45px 85px 85px 5px rgba(24, 23, 21, 0.03);
  border-radius: 65px;
  -webkit-transform: rotate(-35deg);
  transform: rotate(-35deg);
  width: 130px;
  height: 800px;
  top: 0%;
  left: 70%;
}
.shapesContainer .shape4 {
  position: absolute;
  box-shadow: -45px 85px 85px 5px rgba(24, 23, 21, 0.03);
  border-radius: 65px;
  -webkit-transform: rotate(-35deg);
  transform: rotate(-35deg);
  width: 130px;
  height: 800px;
  top: 0%;
  left: 80%;
}

@media (max-width: 960px) {
  .section {
    height: auto;
  }
  .shapesContainer {
    display: block;
  }
}
