@import '~react-image-gallery/styles/css/image-gallery.css';

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
:root {
  --text-primary-color: #202647;
  --text-secondary-color: #8491a5;
  --text-big-title-color: #343c5c;
  --text-big-subtitle-color: #404142;
  --text-big-secondary-subtitle-color: #333333;
  --light-blue: #46a3f2;
  --dark-blue: #2c7ae7;
  --magnetic-blue: #6bc3f0;
  --background-primary-color: #f8f9fe;
  --background-secondary-color: #f6f5fb;
  --background-small-item: #e5f0fa;
  --text-small-item: #545d7a;
  --navbar-color: #f9f8f8;

  --gray-color: rgba(142, 166, 197, 0.8);

  --blue: rgba(89, 160, 223, 0.8);
  --orange: rgba(243, 167, 20, 0.8);
  --green: rgba(77, 186, 146, 0.8);
  --red: rgba(239, 105, 105, 0.9);
  --purple: rgba(168, 153, 210, 0.8);
  --grey: rgba(142, 166, 197, 0.8);

  --primary: rgba(242, 176, 23, 1);
  --primary-medium: rgba(243, 167, 20, 0.8);
  --primary-light: rgba(242, 176, 23, 0.5);
  --secondary: rgba(101, 163, 222, 1);
  --secondary-medium: #46a3f2;
  --secondary-light: #bfd7ed;
  --secondary-dark: #003b73;
  --image-overlay: #e6f4f1;

  --links: #131f58;

  --border-purple: #cce;

  --mov: #d7dfff;
  --mov-light: #eff2ff;
  --mov-dark: #c0ccff;
  --mov-darkest: #443874;

  --primary-color: rgba(242, 176, 23, 1);
  --primary-color-light: rgba(242, 176, 23, 0.7);
  --secondary-color: rgba(101, 163, 222, 1);
}

.App {
  height: 100vh;
}

#root {
  width: 100%;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  color: var(--text-big-secondary-subtitle-color) !important;
  background-color: #ffffff !important;
  margin: 0;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}
::-webkit-scrollbar {
  width: 0.5em;
  height: 5px;
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: lightgrey;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
}

a {
  text-decoration: none;
  color: inherit;
}

.main.controls .toolbar {
  display: none !important;
  visibility: hidden !important;
}

.Typewriter {
  display: inline;
}

.react-images__blanket {
  z-index: 10001 !important;
}

.react-images__positioner {
  z-index: 10002 !important;
}

.grecaptcha-badge {
  z-index: 1000;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

#widget-container {
  margin: 10px 0;
}

#widget-container [id^='gartner-pi-widget'] {
  padding: 10px;
  max-width: 462px;
  width: 100%;
}

#widget-container .gartner-pi-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}

#widget-container [id^='gartner-pi-widget'][data-size='line'] .gartner-pi-link {
  padding: 0;
}

#widget-container
  [id^='gartner-pi-widget'][data-size='line']
  .gartner-pi-link
  .gartner-pi-overall-rating {
  display: flex;
  align-items: center;
}

#widget-container
  [id^='gartner-pi-widget'][data-size='line']
  .gartner-pi-link
  .gartner-pi-overall-rating
  .gartner-pi-translate {
  display: flex;
  align-items: center;
  transform: translateX(0);
}

#widget-container
  [id^='gartner-pi-widget'][data-size='line']
  .gartner-pi-link
  .gartner-pi-overall-rating
  .gartner-pi-translate
  .gartner-pi-star-rating {
  margin-top: 0;
  transform: scale(0.7) translateY(0);
}

#widget-container .gartner-pi-as-of-date {
  font-size: 10px;
}

/* Media Queries */
@media (max-width: 455px), (min-width: 960px) and (max-width: 1289px) {
  #widget-container [id^='gartner-pi-widget'] {
    width: unset;
  }

  #widget-container
    [id^='gartner-pi-widget'][data-size='line']
    .gartner-pi-link
    .gartner-pi-overall-rating {
    display: flex;
    align-items: start;
    gap: 1rem;
  }

  #widget-container
    [id^='gartner-pi-widget'][data-size='line']
    .gartner-pi-link
    .gartner-pi-overall-rating
    .gartner-pi-translate {
    display: flex;
    transform: translateX(0);
    flex-wrap: wrap;
    flex-direction: column;
    flex-grow: 1;
    align-items: start;
  }

  #widget-container
    [id^='gartner-pi-widget'][data-size='line']
    .gartner-pi-link
    .gartner-pi-overall-rating
    .gartner-pi-translate
    .gartner-pi-star-rating {
    margin-top: 0;
    transform: scale(0.7) translate(-25px, -3px);
  }

  #widget-container
    [id^='gartner-pi-widget'][data-size='line']
    .gartner-pi-link
    .gartner-pi-overall-rating
    .gartner-pi-translate
    * {
    margin: 0;
  }

  [id^='gartner-pi-widget'][data-size='line'] .gartner-pi-reviews-link {
    font-weight: 400;
    font-size: 12px;
    display: inline-block;
  }

  [id^='gartner-pi-widget'][data-size='line'] .gartner-pi-sourcing-link {
    display: inline-block;
  }
}
