.section {
  position: relative;
  padding: 50px 0;
  margin: 0;
  background-color: var(--background-secondary-color);
  background: url("../../_res/decor-footer.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.section .container {
  position: relative;
  padding: 0px 15px;
  z-index: 2;
  min-height: 220px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.section .container .logo {
  width: 200px;
}

.section .container .detailsContainer {
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
}

.section .container .detailsContainer .mapContainer {
  width: 30%;
}

.section .container .detailsContainer .aboutContainer {
  width: 40%;
}
.section .container .detailsContainer .aboutContainer .socialContainer {
  margin: 20px 0 0 0;
}
.section
  .container
  .detailsContainer
  .aboutContainer
  .socialContainer
  .socialIcon {
  color: var(--primary-color);
  margin: 0 5px 0 0;
}

.section
  .container
  .detailsContainer
  .aboutContainer
  .socialContainer
  .socialIcon:hover {
  color: var(--primary-light);
}

.section .container .contactContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.section .container .contactContainer .contactLinks {
  position: relative;
  display: flex;
  align-items: center;
}
.section .container .contactContainer .contactLinks a {
  position: relative;
}
.section .container .contactContainer .contactLinks a::before {
  content: "";
  position: absolute;
  bottom: -3px;
  left: 0;
  height: 3px;
  width: 0;
  border-radius: 10px;
  background-color: var(--primary-color);
  transition: 0.3s;
}
.section .container .contactContainer .contactLinks a:hover::before {
  width: 100%;
}
.section .container .contactContainer .contactLinks .contactIcon {
  color: var(--primary-color);
  margin-right: 5px;
}

.section .container .termsContainer {
  margin-top: 20px;
  display: flex;
  justify-content: flex-start;
}

.section .container .termsContainer .termsLink {
  position: relative;
  margin: 0 5px 0 0;
}

.section .container .termsContainer .termsLink::before {
  content: "";
  position: absolute;
  bottom: -3px;
  left: 0;
  height: 3px;
  width: 0;
  border-radius: 10px;
  background-color: var(--primary-color);
  transition: 0.3s;
}

.section .container .termsContainer .termsLink:hover::before {
  width: 100%;
}

@media (max-width: 960px) {
  .section .container .detailsContainer {
    display: flex;
    flex-direction: column;
  }

  .section .container .detailsContainer .mapContainer {
    width: 100%;
  }

  .section .container .detailsContainer .aboutContainer {
    width: 100%;
    margin: 20px 0;
  }

  .section .container .contactContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .section .container .contactContainer .contactLinks {
    display: flex;
    flex-direction: column;
    justify-content: initial;
  }
  .section .container .termsContainer {
    justify-content: center;
  }
}

@media (max-width: 600px) {
  .section .container .detailsContainer {
    text-align: center;
  }
  .section .container .contactContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .section .container .contactContainer .contactLinks {
    margin: 10px 0;
  }
}
