.container {
  width: 40%;
  padding: 0 15px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: center;
  /* align-items: center; */
  overflow: hidden;
}

.container .title {
  background: linear-gradient(90deg, var(--blue) 0%, var(--green) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
  font-size: clamp(2.5rem, 1.5rem + 5vw, 6rem);
  margin-bottom: 1rem;
}

.container .subtitle {
  margin: 50px 0;
}

.container .wordsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  z-index: 2;
  align-items: center;
  align-content: flex-start;
}
.container .wordsContainer .word {
  padding: 0px 25px;
  border-radius: 25px;
  margin: 5px;
  color: #f3f3f3;
  height: auto;
  text-align: center;
}

/* @media (max-width: 1300px) {
  .container .title {
    transform: translateY(20%);
  }
}

@media (max-width: 1700px) {
  :global(#widget-container) {
    transform: translateY(30%);
  }
}

@media (max-height: 900px) {
  .container .title {
    transform: translateY(20%);
  }

  :global(#widget-container) {
    transform: translateY(30%);
  }
} */

@media (max-width: 960px) {
  .container {
    width: 100%;
    padding: 0;
    margin-top: 100px;
  }
}
