.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 4;
}

.modal {
  background-color: #fff;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  position: relative;
  max-width: 400px;
  z-index: 2;
}

.close-btn {
  position: absolute;
  top: 5px;
  right: 5px;
  background: none;
  border: none;
  cursor: pointer;
}

/* Media Queries */
@media screen and (max-width: 480px) {
  .modal {
    max-width: 90%;
  }
}

@media screen and (max-width: 320px) {
  .modal {
    padding: 10px;
  }
}
