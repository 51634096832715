.section {
  padding: 200px 0;
  margin: 50px 0;
  position: relative;
  background-image: url('../../../_res/decor-packages.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.section .container {
  padding: 0 15px;
  width: 70%;
  margin: 0 auto;
}

.container .textContainer .bigTitle {
  font-weight: bold;
  position: relative;
  margin-bottom: 20px;
}
.container .textContainer .bigTitle::after {
  position: absolute;
  content: '';
  height: 3px;
  width: 32px;
  background-color: var(--primary);
  bottom: -10px;
  margin: 0 auto 0;
  left: 95px;
}
.container .textContainer .bigTitle::before {
  position: absolute;
  content: '';
  height: 3px;
  width: 80px;
  background-color: var(--primary-light);
  bottom: -10px;
  left: 0;
}

.container .packagesContainer {
  display: flex;
  justify-content: space-between;
  margin: 50px 0;
}
.container .packagesContainer .package {
  width: 25%;
  padding: 46px 25px 30px;
  -webkit-box-shadow: 0px 6px 40px 0px rgba(148, 146, 245, 0.2);
  box-shadow: 0px 6px 40px 0px rgba(148, 146, 245, 0.2);
  border-radius: 10px;
  position: relative;
  background: #fff;
  text-align: center;
  margin-bottom: 30px;
}

.container .packagesContainer .package .iconContainer {
  color: var(--primary);
  font-size: 35px;
  -webkit-transition: all 0.5s ease 0s;
  -o-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  position: relative;
  z-index: 1;
  margin: 30px auto;
  background: linear-gradient(
    20deg,
    var(--primary) 0%,
    var(--primary-light) 100%
  );
  height: 90px;
  width: 90px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 33% 66% 70% 30%/49% 62% 38% 51% !important;
}
.container .packagesContainer .package .iconContainer img {
  width: 50px;
  height: 50px;
}

.container .packagesContainer .package .title {
  font-weight: bold;
}

.container .packagesContainer .package .subtitle {
  color: var(--text-small-item);
}

@media (max-width: 1300px) {
  .section .container {
    width: 80%;
  }
}

@media (max-width: 960px) {
  .section {
    margin: 50px 0;
  }
  .section .container {
    width: 100%;
  }

  .container .packagesContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .container .packagesContainer .package {
    width: 100%;
    padding: 20px 15px;
  }
  .container .packagesContainer .package .iconContainer {
    height: 60px;
    width: 60px;
  }
  .container .packagesContainer .package .iconContainer img {
    width: 30px;
    height: 30px;
  }
}

@media (max-width: 600px) {
  .section {
    margin: 25px 0;
    padding: 25px 0;
    background-image: none;
  }
  .container .packagesContainer .package {
    overflow: hidden;
  }
  .container .packagesContainer .package .iconContainer {
    position: absolute;
    top: -40px;
    left: -10px;
  }
}
