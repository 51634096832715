.section {
  padding: 0;
  margin: 0;
  position: relative;
}
.section .container {
  padding: 0;
  width: 100%;
  margin: 0 auto;
  text-align: center;
}

.container .textContainer .title {
  font-weight: bold;
  color: var(--text-big-title-color);
  position: relative;
  margin-bottom: 20px;
}

.container .advantagesContainer {
  display: flex;
  justify-content: space-between;
}
.container .advantagesContainer .box {
  width: 20%;
  padding: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-radius: 10px;
}

.container .advantagesContainer .box:nth-child(1) {
  background: rgba(255, 255, 255, 0.4);
}
.container .advantagesContainer .box:nth-child(2) {
  background: rgba(255, 255, 255, 0.6);
}
.container .advantagesContainer .box:nth-child(3) {
  background: rgba(255, 255, 255, 0.8);
}

.container .advantagesContainer .box:nth-child(4) {
  background: rgba(255, 255, 255, 1);
}

.container .advantagesContainer .box .title {
  color: var(--secondary-dark);
  font-weight: bold;
  margin-bottom: 20px;
}
.container .advantagesContainer .box .subtitle {
  margin-bottom: 20px;
}

@media (max-width: 960px) {
  .container .advantagesContainer {
    display: flex;
    flex-flow: wrap;
    justify-content: space-between;
  }
  .container .advantagesContainer .box {
    width: 45%;
  }
}
@media (max-width: 600px) {
  .container .advantagesContainer .box {
    width: 100%;
  }
}
