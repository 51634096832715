.section {
  padding: 50px 0;
  margin: 50px 0;
  position: relative;
}
.section .container {
  padding: 0 15px;
  width: 40%;
  margin: 0 auto;
}

.section .container .quote {
  position: relative;
  text-align: center;
  padding: 30px 50px;
  border-radius: 10px 10px 0 10px;
  box-shadow: 0px 6px 40px 0px rgba(148, 146, 245, 0.2);
  margin-bottom: 30px;
  background: #ffffff;
}

.section .container .quote::before {
  content: "“";
  color: var(--primary);
  position: absolute;
  top: 50px;
  left: 10px;
  display: block;
  font-family: Georgia;
  font-size: 80px;
  line-height: 0;
  display: inline-block;
  display: -webkit-inline-box;
}

.section .container .quote::after {
  content: "";
  position: absolute;
  bottom: -30px;
  right: 10px;
  border-width: 30px 0 0 30px;
  border-style: solid;
  border-color: #ffffff transparent;
  display: block;
  width: 0;
}

.section .container .client {
  letter-spacing: 1.2px;
  text-transform: uppercase;
  text-align: right;
  margin-right: 30px;
}
.section .container .company {
  text-align: right;
  margin-right: 30px;
}

.indicators {
  display: flex;
  justify-content: center;
}

.indicators span {
  height: 30px;
  width: 80px;
  margin: 0 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.indicators span:before {
  content: "";
  height: 10px;
  width: 80px;
  background-color: var(--primary-light);
  transition: all 0.3s ease;
}

.indicators span:hover:before {
  background-color: var(--primary);
}

span.active::before {
  height: 15px;
  width: 15px;
  background-color: var(--border-purple);
}

@media (max-width: 960px) {
  .section .container {
    width: 100%;
  }
}

@media (max-width: 600px) {
  .section {
    margin: 25px 0;
    padding: 25px 0;
  }
}
