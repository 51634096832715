.section {
  padding: 50px 0;
  margin: 100px 0;
  position: relative;
}
.section .container {
  padding: 0 15px;
  width: 70%;
  margin: 0 auto;
  margin-bottom: 50px;
}
.section .container .title {
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
}
.section .container .subtitle {
  font-weight: bold;
  color: var(--text-big-title-color);
}
.section .container .type {
  font-weight: bold;
}

@media (max-width: 960px) {
  .section {
    margin: 50px 0;
  }
  .section .container {
    width: 100%;
  }
}

@media (max-width: 600px) {
  .section {
    margin: 25px 0;
  }
}
