.container {
  width: 50%;
  padding: 0 15px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: center;
  /* align-items: center; */
  overflow: hidden;
}

.container .detailsContainer {
  display: flex;
}

.container .title {
  color: var(--text-big-title-color);
  font-weight: bold;
}

.container .subtitle {
  margin: 50px 0;
}

.container .wordsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  z-index: 2;
  align-items: center;
  align-content: flex-start;
}
@media (max-width: 960px) {
  .container {
    position: relative;
    width: 100%;
    padding: 0 15px;
    margin-top: 100px;
  }
}
