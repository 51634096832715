.container {
  width: 100%;
  position: relative;
  display: flex;
  padding: 15px;
  align-items: center;
}

.container .infoContainer {
  padding: 0 15px;
  width: 50%;
  display: flex;
  flex-direction: column;
}

.container .infoContainer .bigTitle {
  font-weight: bold;
  color: var(--secondary);
  position: relative;
  margin-bottom: 20px;
}

.container .infoContainer .bigSubtitle {
  margin: 20px 0;
}

.container .infoContainer .bigSecondarySubtitle {
  color: var(--text-big-secondary-subtitle-color);
  margin: 20px 0;
}

.customText {
  margin-left: 70px;
  color: var(--primary);
  font-family: "Aldrich, sans-serif";
}

.container .textContainer {
  display: flex;
  flex-flow: wrap;
  margin: 20px 0;
  justify-content: space-around;
}
.container .textContainer .option {
  margin: 10px 2px;
}
.container .textContainer .highlight {
  padding: 5px 5px 5px 10px;
  background-color: var(--secondary);
  color: #ffffff;
  font-weight: bold;
  border-radius: 50px 0 0 50px;
}

.container .animationContainer {
  display: flex;
  align-items: center;
  max-height: 600px;
  justify-content: center;
  width: 50%;
}

.container .videoContainer {
  width: 40%;
  z-index: 2;
  position: relative;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.playerWrapper {
  width: 100%;
  position: relative;
  padding-top: 56.25%; /* 720 / 1280 = 0.5625 */
}

.videoWrapper {
  position: absolute;
  top: 0;
  left: 0;
}

@media (max-width: 960px) {
  .customText {
    margin-left: 0;
  }
}

@media (max-width: 600px) {
  .container {
    margin: 20px 0;
  }
  .container .infoContainer {
    width: 100%;
  }
  .container .animationContainer {
    width: 400px;
    position: absolute;
    z-index: -1;
    opacity: 0.2;
    left: 50%;
    transform: translateX(-50%);
  }
}
