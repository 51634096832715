.section {
  position: relative;
  padding: 50px 0;
  margin: 100px 0;
  background-image: url('../../../_res/bg-right-2.png');
  background-repeat: no-repeat;
  background-position: center right;
  background-size: contain;
}
.section .overlay {
  position: absolute;
  bottom: 0;
  left: -190px;
  width: 400px;
  height: 320px;
  background-image: url('../../../_res/bg-right-2.png');
  background-repeat: no-repeat;
  z-index: 1;
  background-size: contain;
  transform: rotate(180deg);
}

.section .bannerContainer {
  position: relative;
  align-self: center;
  margin: 10px auto;
  margin-bottom: 50px;
  display: flex;
  justify-content: center;
  width: 50%;
  max-width: 100%;
}

.section .bannerContainer .banner {
  border-radius: 20px;
  padding: 10px;
  box-shadow: inset 0px 0px 10px 1px #d3d3d3;
  width: 100%;
}

.section .container {
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 70%;
  margin: 0 auto;
}

.section .container .textContainer .title {
  position: relative;
}
.section .container .textContainer .title .line {
  width: 80px;
  height: 5px;
  background-color: var(--primary-light);
  position: absolute;
  bottom: -10px;
  left: 0px;
  border-radius: 10px;
}
.section .container .textContainer .title:after {
  content: '';
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: var(--primary);
  box-shadow: 0 10px 30px 3px rgba(243, 167, 20, 0.6);
  border-radius: 50%;
  bottom: -12px;
  left: 0px;
  z-index: 1;
  -webkit-animation: slide-br 2s linear infinite alternate-reverse both;
  animation: slide-br 2s linear infinite alternate-reverse both;
}
.section .container .textContainer .titleText {
  font-weight: bold;
  color: var(--secondary);
}

.section .container .textContainer .subtitle {
  margin: 20px 0;
}

.section .container .infoContainer {
  display: flex;
  justify-content: space-between;
}
.section .container .infoContainer .info {
  width: 50%;
  padding-right: 5px;
}
.section .container .infoContainer .graphic {
  position: relative;
  width: 50%;
  background: var(--primary);
  background: linear-gradient(
    to top,
    var(--primary) 0%,
    var(--primary-light) 100%
  );
  padding: 10px 15px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}
.section .container .infoContainer .graphic:before {
  content: '';
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  height: 40px;
  width: 180px;
  background: radial-gradient(
    circle at 5px 5px,
    var(--image-overlay) 0px,
    transparent 5px
  );
  background-size: 15px 20px;
  background-position: 100%;
  background-repeat: repeat;
  z-index: 1;
}
.section .container .infoContainer .graphic:after {
  content: '';
  position: absolute;
  z-index: -1;
  right: 0;
  bottom: 0;
  height: 100px;
  width: 30px;
  background: radial-gradient(
    circle at 5px 5px,
    var(--image-overlay) 0px,
    transparent 5px
  );
  background-size: 15px 20px;
  background-position: 100%;
  background-repeat: repeat;
  z-index: 1;
}
.section .container .infoContainer .graphic .title {
  margin: 10px 0;
  font-weight: bold;
  color: var(--background-secondary-color);
  text-align: center;
}
.section .container .infoContainer .graphic .industriesContainer {
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;
}
.section .container .infoContainer .graphic .industriesContainer .industry {
  padding-left: 35px;
  width: 50%;
  position: relative;
  color: #ffffff;
}
.section
  .container
  .infoContainer
  .graphic
  .industriesContainer
  .industry:before {
  content: '\2713';
  position: absolute;
  width: 20px;
  height: 20px;
  background: #ffffff;
  color: var(--primary);
  top: 7px;
  left: 10px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* .section
  .container
  .infoContainer
  .graphic
  .industriesContainer
  .industry:nth-child(even) {
  align-self: flex-end;
  text-align: right;
} */

@-webkit-keyframes slide-br {
  0% {
    -webkit-transform: translateY(0) translateX(0);
    transform: translateY(0) translateX(0);
  }
  100% {
    -webkit-transform: translateY(0px) translateX(70px);
    transform: translateY(0px) translateX(70px);
  }
}
@keyframes slide-br {
  0% {
    -webkit-transform: translateY(0) translateX(0);
    transform: translateY(0) translateX(0);
  }
  100% {
    -webkit-transform: translateY(0px) translateX(70px);
    transform: translateY(0px) translateX(70px);
  }
}

@media (max-width: 960px) {
  .section {
    margin: 50px 0;
  }
  .section .container {
    width: 100%;
  }
  .section .container .infoContainer {
    flex-direction: column;
  }
  .section .container .infoContainer .info {
    width: 100%;
  }
  .section .container .infoContainer .graphic {
    width: 100%;
    margin-top: 50px;
  }
  .section .bannerContainer {
    position: relative;
    align-self: center;
    margin: 10px auto;
    margin-bottom: 50px;
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: 95%;
  }
}

@media (max-width: 600px) {
  .section {
    margin: 25px 0;
    padding: 25px 0;
  }
  .section .bannerContainer {
    position: relative;
    align-self: center;
    margin: 10px auto;
    margin-bottom: 50px;
    display: flex;
    justify-content: center;
  }
}
