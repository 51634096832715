.section {
  padding: 50px 0;
  margin: 100px 0;
  position: relative;
}
.section .container {
  padding: 0 15px;
  width: 70%;
  margin: 0 auto;
}
.section .container::before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  height: 300px;
  width: 180px;
  background: radial-gradient(
    circle at 10px 7px,
    var(--image-overlay) 5px,
    transparent 5px
  );
  background-size: 30px 30px;
  background-position: 100%;
  background-repeat: repeat;
}
.section .container::after {
  content: "";
  position: absolute;
  z-index: -1;
  right: 0;
  bottom: -50px;
  height: 180px;
  width: 300px;
  background: radial-gradient(
    circle at 10px 7px,
    var(--secondary-light) 5px,
    transparent 5px
  );
  background-size: 30px 30px;
  background-position: 100%;
  background-repeat: repeat;
}
.section .container .itemsContainer {
  display: flex;
  flex-direction: column;
}
.section .container .itemsContainer .box {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 50px 0;
}
.section .container .itemsContainer .box:nth-child(even) {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row-reverse;
  margin: 20px 0;
}
.section .container .itemsContainer .box .textContainer {
  width: 50%;
}
.section .container .itemsContainer .box .textContainer .title {
  position: relative;
  margin: 20px 0;
}
.section .container .itemsContainer .box .textContainer .title .text {
  font-weight: bold;
  color: var(--secondary);
}

.section .container .itemsContainer .box .textContainer .title .line {
  width: 80px;
  height: 5px;
  background-color: var(--primary-light);
  position: absolute;
  bottom: -10px;
  left: 0px;
  border-radius: 10px;
}
.section .container .itemsContainer .box .textContainer .title:after {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: var(--primary);
  box-shadow: 0 10px 30px 3px rgba(243, 167, 20, 0.6);
  border-radius: 50%;
  bottom: -12px;
  left: 0px;
  z-index: 1;
  -webkit-animation: slide-br 2s linear infinite alternate-reverse both;
  animation: slide-br 2s linear infinite alternate-reverse both;
}
.section .container .itemsContainer .box .textContainer .subtitle {
  margin-bottom: 20px;
  text-align: justify;
}

.section .container .itemsContainer .box .videoContainer {
  position: relative;
  width: 40%;
  margin: 0 auto;
}
.section .container .itemsContainer .box .videoContainer:before {
  position: absolute;
  left: -5px;
  top: -5px;
  width: 94%;
  height: 88%;
  z-index: -1;
  content: "";
  background-color: var(--primary-light);
  transition: 0.5s;
  border-radius: 6px;
}
.section .container .itemsContainer .box .videoContainer:after {
  position: absolute;
  right: -5px;
  bottom: -5px;
  width: 94%;
  height: 88%;
  z-index: -1;
  content: "";
  background-color: var(--primary-color);
  transition: 0.5s;
  border-radius: 6px;
}
.section .container .itemsContainer .box .videoContainer .playerWrapper {
  position: relative;
  padding-top: 56.25%;
}
.section
  .container
  .itemsContainer
  .box
  .videoContainer
  .playerWrapper
  .videoWrapper {
  position: absolute;
  top: 0;
  left: 0;
}

/* ----------------------------------------------
 * Generated by Animista on 2020-12-17 17:12:58
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation rotate-bl
 * ----------------------------------------
 */
@-webkit-keyframes rotate-bl {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: center right;
    transform-origin: center right;
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
    -webkit-transform-origin: center right;
    transform-origin: center right;
  }
}
@keyframes rotate-bl {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: center right;
    transform-origin: center right;
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
    -webkit-transform-origin: center right;
    transform-origin: center right;
  }
}

@-webkit-keyframes slide-br {
  0% {
    -webkit-transform: translateY(0) translateX(0);
    transform: translateY(0) translateX(0);
  }
  100% {
    -webkit-transform: translateY(0px) translateX(70px);
    transform: translateY(0px) translateX(70px);
  }
}
@keyframes slide-br {
  0% {
    -webkit-transform: translateY(0) translateX(0);
    transform: translateY(0) translateX(0);
  }
  100% {
    -webkit-transform: translateY(0px) translateX(70px);
    transform: translateY(0px) translateX(70px);
  }
}
/* ----------------------------------------------
 * Generated by Animista on 2020-12-18 12:58:51
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-top
 * ----------------------------------------
 */
@-webkit-keyframes slide-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-25px);
    transform: translateY(-25px);
  }
}
@keyframes slide-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-25px);
    transform: translateY(-25px);
  }
}

@media (max-width: 960px) {
  .section {
    margin: 50px 0;
  }
  .section .container {
    width: 100%;
  }
  .section .container .itemsContainer .box {
    flex-direction: column;
  }
  .section .container .itemsContainer .box:nth-child(even) {
    flex-direction: column;
  }
  .section .container .itemsContainer .box .textContainer {
    width: 100%;
    margin: 20px 0;
  }

  .section .container .itemsContainer .box .textContainer .subtitle {
    text-align: inherit;
  }
  .section .container .itemsContainer .box .videoContainer {
    width: 70%;
  }
}

@media (max-width: 600px) {
  .section {
    padding: 25px 0;
    margin: 25px 0;
  }
  .section .container::before {
    content: "";
    position: absolute;
    z-index: -1;
    opacity: 0.5;
    left: 0;
    top: 50px;
    height: 70px;
    width: 150px;
    background: radial-gradient(
      circle at 10px 7px,
      var(--image-overlay) 5px,
      transparent 5px
    );
    background-size: 15px 15px;
    background-position: 100%;
    background-repeat: repeat;
  }
  .section .container::after {
    content: "";
    position: absolute;
    opacity: 0.5;
    z-index: -1;
    right: 0;
    bottom: 0;
    height: 70px;
    width: 150px;
    background: radial-gradient(
      circle at 10px 7px,
      var(--secondary-light) 5px,
      transparent 5px
    );
    background-size: 15px 15px;
    background-position: 100%;
    background-repeat: repeat;
  }
  .section .container .itemsContainer .box {
    margin: 20px 0;
  }
  .section .container .itemsContainer .box .textContainer .title img {
    width: 50px;
  }
  .section .container .itemsContainer .box .textContainer {
    width: 100%;
  }
  .section .container .itemsContainer .box .animationContainer {
    width: 100%;
    height: 200px;
    animation: Items_slide-top__2MejX 5s linear infinite alternate-reverse both;
    position: absolute;
    z-index: -1;
    opacity: 0.2;
  }

  .section .container .itemsContainer .box .textContainer .title .line {
    bottom: -13px;
    left: 0;
  }
  .section .container .itemsContainer .box .textContainer .title:after {
    bottom: -15px;
    left: 0;
    -webkit-animation: slide-br 2s linear infinite alternate-reverse both;
    animation: slide-br 2s linear infinite alternate-reverse both;
  }
  .section .container .itemsContainer .box .textContainer .title:before {
    width: 40px;
    height: 40px;
    -webkit-animation: rotate-bl 5s linear infinite reverse forwards;
    animation: rotate-bl 5s linear infinite reverse forwards;
  }
}
