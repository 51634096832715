.section {
	padding: 50px 0;
	margin: 50px 0;
	position: relative;
}

.section .container {
	padding: 0 15px;
}

.container .textContainer .bigTitle {
	font-weight: bold;
	position: relative;
	margin-bottom: 20px;
}
.container .textContainer .bigTitle::after {
	position: absolute;
	content: '';
	height: 3px;
	width: 32px;
	background-color: var(--primary-color);
	bottom: -10px;
	margin: 0 auto 0;
	left: 95px;
}
.container .textContainer .bigTitle::before {
	position: absolute;
	content: '';
	height: 3px;
	width: 80px;
	background-color: var(--primary-color-light);
	bottom: -10px;
	left: 0;
}
.container .textContainer .bigSubtitle {
	margin: 20px 0;
}

.container .clientsContainer {
	height: 180px;
	width: 100%;
	position: relative;
	display: flex;
	flex-wrap: nowrap;
	justify-content: space-around;
	align-items: center;
	overflow: hidden;
	overflow-x: auto;
}

.container .client {
	max-width: 50%;
	display: flex;
	justify-content: center;
	border-radius: 10px;
	padding: 0px 10px;
	background: #fff;
}

.container .client img {
	cursor: pointer;
	/* filter: gray; 
  -webkit-filter: grayscale(1); 
  filter: grayscale(1);  */
	width: 100%;
}

/* Disable grayscale on hover */
.container .client img:hover {
	-webkit-filter: grayscale(0);
	filter: none;
	-webkit-animation: jello-horizontal 0.9s both;
	animation: jello-horizontal 0.9s both;
}

.customText {
	color: var(--primary-color);
	font-family: 'Aldrich, sans-serif';
	text-transform: uppercase;
}

@-webkit-keyframes jello-horizontal {
	0% {
		-webkit-transform: scale3d(1, 1, 1);
		transform: scale3d(1, 1, 1);
	}
	30% {
		-webkit-transform: scale3d(1.25, 0.75, 1);
		transform: scale3d(1.25, 0.75, 1);
	}
	40% {
		-webkit-transform: scale3d(0.75, 1.25, 1);
		transform: scale3d(0.75, 1.25, 1);
	}
	50% {
		-webkit-transform: scale3d(1.15, 0.85, 1);
		transform: scale3d(1.15, 0.85, 1);
	}
	65% {
		-webkit-transform: scale3d(0.95, 1.05, 1);
		transform: scale3d(0.95, 1.05, 1);
	}
	75% {
		-webkit-transform: scale3d(1.05, 0.95, 1);
		transform: scale3d(1.05, 0.95, 1);
	}
	100% {
		-webkit-transform: scale3d(1, 1, 1);
		transform: scale3d(1, 1, 1);
	}
}
@keyframes jello-horizontal {
	0% {
		-webkit-transform: scale3d(1, 1, 1);
		transform: scale3d(1, 1, 1);
	}
	30% {
		-webkit-transform: scale3d(1.25, 0.75, 1);
		transform: scale3d(1.25, 0.75, 1);
	}
	40% {
		-webkit-transform: scale3d(0.75, 1.25, 1);
		transform: scale3d(0.75, 1.25, 1);
	}
	50% {
		-webkit-transform: scale3d(1.15, 0.85, 1);
		transform: scale3d(1.15, 0.85, 1);
	}
	65% {
		-webkit-transform: scale3d(0.95, 1.05, 1);
		transform: scale3d(0.95, 1.05, 1);
	}
	75% {
		-webkit-transform: scale3d(1.05, 0.95, 1);
		transform: scale3d(1.05, 0.95, 1);
	}
	100% {
		-webkit-transform: scale3d(1, 1, 1);
		transform: scale3d(1, 1, 1);
	}
}

@media (max-width: 960px) {
	.section {
		margin: 50px 0;
	}
}
@media (max-width: 600px) {
	.section {
		margin: 25px 0;
		padding: 25px 0;
	}
	.container .clientsContainer {
		height: 100px;
	}
}
