.section {
	padding: 50px 0;
	margin: 100px 0;
	position: relative;
}
.section .container {
	padding: 0 15px;
	width: 70%;
	margin: 0 auto;
}
.section .container .itemsContainer {
	display: flex;
	flex-direction: column;
}
.section .container .itemsContainer div .box {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 20px 0;
}
.section .container .itemsContainer div:nth-child(2) .box {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-direction: row-reverse;
	margin: 20px 0;
}
.section .container .itemsContainer .box:nth-child(4) {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-direction: row-reverse;
	margin: 20px 0;
}
.section .container .itemsContainer .box .textContainer {
	width: 50%;
}
.section .container .itemsContainer .box .textContainer .title {
	position: relative;
	display: flex;
	margin: 20px 0;
}
.section .container .itemsContainer .box .textContainer img {
	position: relative;
	width: 35px;
}

.section .container .itemsContainer .box .iconContainer {
	position: relative;
	display: flex;
}
.section .container .itemsContainer .box .figuresContainer {
	position: absolute;
	border-radius: 33% 66% 70% 30%/49% 62% 38% 51% !important;
	background: var(--primary-light);
	z-index: -1;
	top: 50%;
	left: 20px;
	width: 50px;
	height: 50px;
	-webkit-transform-origin: center center;
	-moz-transform-origin: center center;
	-ms-transform-origin: center center;
	-o-transform-origin: center center;
	transform-origin: center center;
	-webkit-animation: service_hexagon 8s infinite linear;
	-moz-animation: service_hexagon 8s infinite linear;
	-o-animation: service_hexagon 8s infinite linear;
	animation: service_hexagon 8s infinite linear;
}

.section .container .itemsContainer .box .textContainer .title .line {
	width: 80px;
	height: 5px;
	background-color: var(--primary-light);
	position: absolute;
	bottom: -10px;
	left: 0px;
	border-radius: 10px;
}
.section .container .itemsContainer .box .textContainer .title:after {
	content: '';
	position: absolute;
	width: 10px;
	height: 10px;
	background-color: var(--primary);
	box-shadow: 0 10px 30px 3px rgba(243, 167, 20, 0.6);
	border-radius: 50%;
	bottom: -12px;
	left: 0px;
	z-index: 1;
	-webkit-animation: slide-br 2s linear infinite alternate-reverse both;
	animation: slide-br 2s linear infinite alternate-reverse both;
}
.section .container .itemsContainer .box .textContainer .titleText {
	font-weight: bold;
	color: var(--secondary);
}

.section .container .itemsContainer .box .textContainer .subtitle2 {
	margin: 20px 0;
}
.section .container .itemsContainer .box .animationContainer {
	width: 50%;
	height: 300px;
	position: relative;
}
.section .container .itemsContainer div:first-child .box .animationContainer {
	-webkit-transform: scale(-1, 1);
	-moz-transform: scale(-1, 1);
	-o-transform: scale(-1, 1);
	-ms-transform: scale(-1, 1);
	transform: scale(-1, 1);
}
.section
	.container
	.itemsContainer
	.box:nth-child(odd)
	.animationContainer
	.overlayFig {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 250px;
	height: 250px;
	background: #e6f4f1;
	-webkit-transform: translate(-50%, -50%);
	-moz-transform: translate(-50%, -50%);
	-o-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	z-index: -1;
	border-radius: 40% 60% 70% 30% / 40% 40% 60% 50%;
	animation: morph 5s linear infinite;
}
.section
	.container
	.itemsContainer
	.box:nth-child(even)
	.animationContainer
	.overlayFig {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 250px;
	height: 250px;
	background: #bfd7ed;
	-webkit-transform: translate(-50%, -50%);
	-moz-transform: translate(-50%, -50%);
	-o-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	z-index: -1;
	border-radius: 40% 60% 70% 30% / 40% 40% 60% 50%;
	animation: morph 5s linear infinite;
}

.section .container .itemsContainer .box .animationContainer img {
	width: 100%;
	height: 100%;
}

@keyframes morph {
	0%,
	100% {
		border-radius: 40% 60% 70% 30% / 40% 40% 60% 50%;
	}
	34% {
		border-radius: 70% 30% 50% 50% / 30% 30% 70% 70%;
	}
	67% {
		border-radius: 100% 60% 60% 100% / 100% 100% 60% 60%;
	}
}

/* ----------------------------------------------
   * Generated by Animista on 2020-12-17 17:12:58
   * Licensed under FreeBSD License.
   * See http://animista.net/license for more info. 
   * w: http://animista.net, t: @cssanimista
   * ---------------------------------------------- */

/**
   * ----------------------------------------
   * animation rotate-bl
   * ----------------------------------------
   */
@-webkit-keyframes rotate-bl {
	0% {
		-webkit-transform: rotate(0);
		transform: rotate(0);
		-webkit-transform-origin: center right;
		transform-origin: center right;
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
		-webkit-transform-origin: center right;
		transform-origin: center right;
	}
}
@keyframes rotate-bl {
	0% {
		-webkit-transform: rotate(0);
		transform: rotate(0);
		-webkit-transform-origin: center right;
		transform-origin: center right;
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
		-webkit-transform-origin: center right;
		transform-origin: center right;
	}
}

@-webkit-keyframes slide-br {
	0% {
		-webkit-transform: translateY(0) translateX(0);
		transform: translateY(0) translateX(0);
	}
	100% {
		-webkit-transform: translateY(0px) translateX(70px);
		transform: translateY(0px) translateX(70px);
	}
}
@keyframes slide-br {
	0% {
		-webkit-transform: translateY(0) translateX(0);
		transform: translateY(0) translateX(0);
	}
	100% {
		-webkit-transform: translateY(0px) translateX(70px);
		transform: translateY(0px) translateX(70px);
	}
}
/* ----------------------------------------------
   * Generated by Animista on 2020-12-18 12:58:51
   * Licensed under FreeBSD License.
   * See http://animista.net/license for more info. 
   * w: http://animista.net, t: @cssanimista
   * ---------------------------------------------- */

/**
   * ----------------------------------------
   * animation slide-top
   * ----------------------------------------
   */
@-webkit-keyframes slide-top {
	0% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}
	100% {
		-webkit-transform: translateY(-25px);
		transform: translateY(-25px);
	}
}
@keyframes slide-top {
	0% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}
	100% {
		-webkit-transform: translateY(-25px);
		transform: translateY(-25px);
	}
}

@media (max-width: 960px) {
	.section {
		margin: 50px 0;
	}
	.section .container {
		width: 100%;
	}
}

@media (max-width: 600px) {
	.section {
		margin: 25px 0;
		padding: 25px 0;
	}
	.section .container .itemsContainer .box .textContainer .title img {
		width: 50px;
	}
	.section .container .itemsContainer .box .textContainer {
		width: 100%;
	}
	.section .container .itemsContainer .box .animationContainer {
		width: 100%;
		height: 200px;
		animation: Items_slide-top__2MejX 5s linear infinite alternate-reverse both;
		position: absolute;
		z-index: -1;
		opacity: 0.2;
	}
}

@-webkit-keyframes service_hexagon {
	0% {
		-webkit-transform: translate(-50%, -50%) rotate(0deg);
		-moz-transform: translate(-50%, -50%) rotate(0deg);
		-o-transform: translate(-50%, -50%) rotate(0deg);
		transform: translate(-50%, -50%) rotate(0deg);
	}

	100% {
		-webkit-transform: translate(-50%, -50%) rotate(360deg);
		-moz-transform: translate(-50%, -50%) rotate(360deg);
		-o-transform: translate(-50%, -50%) rotate(360deg);
		transform: translate(-50%, -50%) rotate(360deg);
	}
}
@keyframes service_hexagon {
	0% {
		-webkit-transform: translate(-50%, -50%) rotate(0deg);
		-moz-transform: translate(-50%, -50%) rotate(0deg);
		-o-transform: translate(-50%, -50%) rotate(0deg);
		transform: translate(-50%, -50%) rotate(0deg);
	}

	100% {
		-webkit-transform: translate(-50%, -50%) rotate(360deg);
		-moz-transform: translate(-50%, -50%) rotate(360deg);
		-o-transform: translate(-50%, -50%) rotate(360deg);
		transform: translate(-50%, -50%) rotate(360deg);
	}
}
