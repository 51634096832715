.section {
  position: relative;
  padding: 50px 0;
  margin: 100px 0;
  overflow: hidden;
}
.section .container {
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  width: 70%;
  margin: 0 auto;
}

.section .container .titleContainer {
  position: relative;
}
.section .container .titleContainer .line {
  width: 80px;
  height: 5px;
  background-color: var(--primary-light);
  position: absolute;
  bottom: -10px;
  left: 0px;
  border-radius: 10px;
}
.section .container .titleContainer:after {
  content: '';
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: var(--primary);
  box-shadow: 0 10px 30px 3px rgba(243, 167, 20, 0.6);
  border-radius: 50%;
  bottom: -12px;
  left: 0px;
  z-index: 1;
  -webkit-animation: slide-br 2s linear infinite alternate-reverse both;
  animation: slide-br 2s linear infinite alternate-reverse both;
}
.section .container .titleText {
  font-weight: bold;
  color: var(--secondary);
}

.eventContainer .infoContainer .dateContainer {
  margin: 20px 0;
  display: flex;
  justify-content: space-between;
}

.eventContainer .infoContainer .dateContainer .location {
  display: flex;
  align-items: center;
  align-content: center;
}
.eventContainer .infoContainer .location .icon {
  color: var(--secondary);
}

.eventContainer .infoContainer .dateContainer .location,
.eventContainer .infoContainer .dateContainer .date {
  border-radius: 5px;
  padding: 5px 15px;
  font-weight: bold;
  background: #ffffff;
  box-shadow: inset 0 0 10px -5px rgba(0, 0, 0, 0.3);
}

.section .infoContainer .blogTitle {
  margin-top: 70px;
  margin-bottom: 20px;
  text-align: center;
}

.section .infoContainer .blogTitle a {
  text-decoration: none;
}

.section .infoContainer .blogTitle a h6 {
  font-size: 1.5rem;
}

@-webkit-keyframes slide-br {
  0% {
    -webkit-transform: translateY(0) translateX(0);
    transform: translateY(0) translateX(0);
  }
  100% {
    -webkit-transform: translateY(0px) translateX(70px);
    transform: translateY(0px) translateX(70px);
  }
}
@keyframes slide-br {
  0% {
    -webkit-transform: translateY(0) translateX(0);
    transform: translateY(0) translateX(0);
  }
  100% {
    -webkit-transform: translateY(0px) translateX(70px);
    transform: translateY(0px) translateX(70px);
  }
}

.videoContainer {
  position: relative;
  padding-bottom: calc(var(--aspect-ratio, 0.5625) * 100%);
  height: 0;
  width: 100%;
}
.videoWrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
} */

@media (max-width: 960px) {
  .section {
    margin: 50px 0;
  }
  .section .container {
    width: 100%;
  }
}

@media (max-width: 960px) {
  .eventContainer .detailsContainer {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
  }
  .eventContainer .textContainer {
    width: 100%;
  }
}

@media (max-width: 600px) {
  .eventContainer .infoContainer .dateContainer {
    margin: 20px 0;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
  .eventContainer .infoContainer .dateContainer .location {
    margin: 10px 0;
    text-align: center;
    display: flex;
    justify-content: center;
  }
  .eventContainer .infoContainer .dateContainer .date {
    margin: 10px 0;
    text-align: center;
    display: flex;
    justify-content: center;
  }

  .videoContainer {
    position: relative;
    width: 100%;
    margin: 0 auto;
  }
}
/* @media (max-width: 600px) {
	.section {
		margin: 25px 0;
		padding: 25px 0;
	}
} */

/* .section {
	position: relative;
	padding: 50px 0;
	margin: 100px 0;
}

.section .container {
	padding: 0 15px;
	display: flex;
	flex-direction: column;
	width: 70%;
	margin: 0 auto;
	overflow: hidden;
}

.eventContainer {
	margin: 20px 0;
	display: flex;
	flex-direction: column;
}
.eventContainer .infoContainer {
	margin: 20px 0;
	display: flex;
	flex-direction: column;
}

.eventContainer .infoContainer .dateContainer {
	margin: 20px 0;
	display: flex;
	justify-content: space-between;
}

.eventContainer .infoContainer .dateContainer .location {
	display: flex;
	align-items: center;
	align-content: center;
}
.eventContainer .infoContainer .location .icon {
	color: var(--secondary);
}

.eventContainer .infoContainer .titleContainer {
	text-align: center;
}

.eventContainer .infoContainer .dateContainer .location {
	border-radius: 5px;
	padding: 5px 15px;
	font-weight: bold;
	background: #ffffff;
	box-shadow: inset 0 0 10px -5px rgba(0, 0, 0, 0.3);
}
.eventContainer .infoContainer .dateContainer .date {
	border-radius: 5px;
	padding: 5px 15px;
	font-weight: bold;
	background: #ffffff;
	box-shadow: inset 0 0 10px -5px rgba(0, 0, 0, 0.3);
}

.eventContainer .detailsContainer h6 {
	margin: 20px 0;
}
.eventContainer .detailsContainer {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.eventContainer .detailsContainer {
	display: flex;
	flex-direction: column;
}

.eventContainer .detailsContainer .textContainer {
}
.eventContainer .detailsContainer .galleryWrapper {
}

.gallery {
}
.videoContainer {
	position: relative;
	padding-bottom: calc(var(--aspect-ratio, 0.5625) * 100%);
	height: 0;
	width: 100%;
}
.videoWrapper {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
@media (max-width: 960px) {
	.eventContainer .detailsContainer {
		display: flex;
		justify-content: space-between;
		flex-direction: column;
		align-items: center;
	}
	.eventContainer .textContainer {
		width: 100%;
	}
}

@media (max-width: 600px) {
	.eventContainer .infoContainer .dateContainer {
		margin: 20px 0;
		display: flex;
		justify-content: space-between;
		flex-direction: column;
	}
	.eventContainer .infoContainer .dateContainer .location {
		margin: 10px 0;
		text-align: center;
		display: flex;
		justify-content: center;
	}
	.eventContainer .infoContainer .dateContainer .date {
		margin: 10px 0;
		text-align: center;
		display: flex;
		justify-content: center;
	}

	.videoContainer {
		position: relative;
		width: 100%;
		margin: 0 auto;
	}
}

.section .container .titleContainer {
	position: relative;
}
.section .container .titleContainer .line {
	width: 80px;
	height: 5px;
	background-color: var(--primary-light);
	position: absolute;
	bottom: -10px;
	left: 0px;
	border-radius: 10px;
}
.section .container .titleContainer:after {
	content: '';
	position: absolute;
	width: 10px;
	height: 10px;
	background-color: var(--primary);
	box-shadow: 0 10px 30px 3px rgba(243, 167, 20, 0.6);
	border-radius: 50%;
	bottom: -12px;
	left: 0px;
	z-index: 1;
	-webkit-animation: slide-br 2s linear infinite alternate-reverse both;
	animation: slide-br 2s linear infinite alternate-reverse both;
}
.section .container .titleText {
	font-weight: bold;
	color: var(--secondary);
}

@-webkit-keyframes slide-br {
	0% {
		-webkit-transform: translateY(0) translateX(0);
		transform: translateY(0) translateX(0);
	}
	100% {
		-webkit-transform: translateY(0px) translateX(70px);
		transform: translateY(0px) translateX(70px);
	}
}
@keyframes slide-br {
	0% {
		-webkit-transform: translateY(0) translateX(0);
		transform: translateY(0) translateX(0);
	}
	100% {
		-webkit-transform: translateY(0px) translateX(70px);
		transform: translateY(0px) translateX(70px);
	}
}

@media (max-width: 960px) {
	.section {
		margin: 50px 0;
	}
	.section .container {
		width: 100%;
	}
}

@media (max-width: 600px) {
	.section {
		margin: 25px 0;
		padding: 25px 0;
	}
} */
