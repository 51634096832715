.navbar {
  padding: 20px 15px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  z-index: 2;
  position: sticky;
  width: 100%;
  background: transparent;
  margin-bottom: -80px;
  top: 0;
  z-index: 4;
  transition: all 0.3s ease;
}

.menuIcon {
  cursor: pointer;
  color: var(--light-blue);
  height: 30px;
  z-index: 99999;
  display: flex;
  display: none;
  align-items: center;
  font-size: 20px;
}
.imageLogo {
  max-width: 200px;
}
.listItems {
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: flex-end;
  list-style: none;
}

.listItems li a {
  text-decoration: none;
  color: var(--links);
  font-weight: bold;
  margin: 0 10px;
  list-style: none;
  position: relative;
  cursor: pointer;
  font-size: 20px;
  font-weight: 500;
  text-transform: capitalize;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  padding-bottom: 0;
  margin-left: 20px;
  margin-right: 20px;
  transition: all 0.2s ease-out;
}
.listItems li a:hover {
  color: var(--primary);
}

.active {
  color: var(--dark-blue) !important;
}

@media (max-width: 960px) {
  .navbar {
    justify-content: space-between;
    background-color: var(--background-secondary-color);
    background: url('../../_res/decor-footer.jpg');
    background-repeat: no-repeat;
    background-size: cover;
  }
  .listItems {
    text-align: center;
    background-color: var(--navbar-color);
    flex-direction: column;
    width: 100%;
    height: 500px;
    position: absolute;
    left: 0;
    top: -100vh;
    transition: all 0.3s ease;
    opacity: 0;
  }
  .listItems.active {
    top: 0px;
    transition: all 0.2s ease;
    z-index: 9999;
    justify-content: center;
    opacity: 1;
    height: 100vh;
    background: url('../../_res/decor-footer.jpg');
    background-repeat: no-repeat;
    background-size: cover;
  }
  .listItems.active li {
    margin: 10px 0;
  }
  .listItems.active li a {
    font-size: 20px;
  }
  .menuIcon {
    display: block;
  }
}
