.section {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.section .container {
  width: 100%;
  border-radius: 10px;
  position: relative;
  z-index: -1;
  opacity: 0.2;
}
.section .container .videoIcon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  display: none;
  visibility: hidden;
}

.section .container .videoIcon .iconPlay {
  cursor: pointer;
  width: 75px;
  height: 75px;
  line-height: 75px;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  position: relative;
  color: #0c5adb;
  -webkit-animation: ripple-blue 1s linear infinite;
  -moz-animation: ripple-blue 1s linear infinite;
  -o-animation: ripple-blue 1s linear infinite;
  animation: ripple-blue 1s linear infinite;
  transition: 0.5s;
  -webkit-transition: 0.5s;
  background: #fff;
  z-index: 44;
}

@-webkit-keyframes ripple-blue {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(0, 81, 255, 0.3),
      0 0 0 10px rgba(0, 81, 255, 0.3), 0 0 0 20px rgba(0, 81, 255, 0.3);
    box-shadow: 0 0 0 0 rgba(0, 81, 255, 0.3), 0 0 0 10px rgba(0, 81, 255, 0.3),
      0 0 0 20px rgba(0, 81, 255, 0.3);
  }
  100% {
    -webkit-box-shadow: 0 0 0 10px rgba(0, 81, 255, 0.3),
      0 0 0 20px rgba(0, 81, 255, 0.3), 0 0 0 30px rgba(0, 81, 255, 0);
    box-shadow: 0 0 0 10px rgba(0, 81, 255, 0.3),
      0 0 0 20px rgba(0, 81, 255, 0.3), 0 0 0 30px rgba(0, 81, 255, 0);
  }
}
@-moz-keyframes ripple-blue {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(0, 81, 255, 0.3),
      0 0 0 10px rgba(0, 81, 255, 0.3), 0 0 0 20px rgba(0, 81, 255, 0.3);
    box-shadow: 0 0 0 0 rgba(0, 81, 255, 0.3), 0 0 0 10px rgba(0, 81, 255, 0.3),
      0 0 0 20px rgba(0, 81, 255, 0.3);
  }
  100% {
    -webkit-box-shadow: 0 0 0 10px rgba(0, 81, 255, 0.3),
      0 0 0 20px rgba(0, 81, 255, 0.3), 0 0 0 30px rgba(0, 81, 255, 0);
    box-shadow: 0 0 0 10px rgba(0, 81, 255, 0.3),
      0 0 0 20px rgba(0, 81, 255, 0.3), 0 0 0 30px rgba(0, 81, 255, 0);
  }
}
@-o-keyframes ripple-blue {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(0, 81, 255, 0.3),
      0 0 0 10px rgba(0, 81, 255, 0.3), 0 0 0 20px rgba(0, 81, 255, 0.3);
    box-shadow: 0 0 0 0 rgba(0, 81, 255, 0.3), 0 0 0 10px rgba(0, 81, 255, 0.3),
      0 0 0 20px rgba(0, 81, 255, 0.3);
  }
  100% {
    -webkit-box-shadow: 0 0 0 10px rgba(0, 81, 255, 0.3),
      0 0 0 20px rgba(0, 81, 255, 0.3), 0 0 0 30px rgba(0, 81, 255, 0);
    box-shadow: 0 0 0 10px rgba(0, 81, 255, 0.3),
      0 0 0 20px rgba(0, 81, 255, 0.3), 0 0 0 30px rgba(0, 81, 255, 0);
  }
}
@keyframes ripple-blue {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(0, 81, 255, 0.3),
      0 0 0 10px rgba(0, 81, 255, 0.3), 0 0 0 20px rgba(0, 81, 255, 0.3);
    box-shadow: 0 0 0 0 rgba(0, 81, 255, 0.3), 0 0 0 10px rgba(0, 81, 255, 0.3),
      0 0 0 20px rgba(0, 81, 255, 0.3);
  }
  100% {
    -webkit-box-shadow: 0 0 0 10px rgba(0, 81, 255, 0.3),
      0 0 0 20px rgba(0, 81, 255, 0.3), 0 0 0 30px rgba(0, 81, 255, 0);
    box-shadow: 0 0 0 10px rgba(0, 81, 255, 0.3),
      0 0 0 20px rgba(0, 81, 255, 0.3), 0 0 0 30px rgba(0, 81, 255, 0);
  }
}

.playerWrapper {
  width: 100%;
  position: relative;
  padding-top: 56.25%; /* 720 / 1280 = 0.5625 */
}

.videoWrapper {
  position: absolute;
  top: 0;
  left: 0;
}
@keyframes rotateme {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes rotateme {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

video:hover,
video:focus {
  outline: 0;
}

::-webkit-media-controls {
  background-color: transparent;
}
video::-webkit-media-controls-panel {
  width: 40px;
}

/* MEDIA QUERIES */

@media (max-width: 960px) {
  .section {
    width: 100%;
    height: 100%;
    position: relative;
    display: block;
    padding: 0 15px;
  }
  .section::before {
    content: none;
  }
  .section .container {
    width: 100%;
    margin: 50px auto;
    opacity: 1;
    z-index: 1;
  }
}
