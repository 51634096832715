.section {
  width: 50%;
  padding: 50px 0;
  margin: 100px 0;
}

.section .container {
  width: 40%;
  margin: 0 auto;
  display: flex;
}

.section .container .circles::after {
  content: "";
  position: absolute;
  right: -80px;
  top: 50px;
  width: 40px;
  height: 40px;
  background-color: var(--primary);
  border-radius: 50%;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(var(--primary)),
    to(var(--primary-light))
  );
  background: -o-linear-gradient(
    left,
    var(--primary) 0,
    var(--primary-light) 100%
  );
  background: linear-gradient(
    to right,
    var(--primary) 0,
    var(--primary-light) 100%
  );
}
.section .container .circles {
  position: relative;
}
.section .container .circles::before {
  content: "";
  position: absolute;
  right: -50px;
  top: -50px;
  width: 70px;
  height: 70px;
  background-color: var(--primary);
  border-radius: 50%;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(var(--primary)),
    to(var(--primary-light))
  );
  background: -o-linear-gradient(
    left,
    var(--primary) 0,
    var(--primary-light) 100%
  );
  background: linear-gradient(
    to right,
    var(--primary) 0,
    var(--primary-light) 100%
  );
}

.section .container .circles .mainCircle {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: #fff;
  -webkit-box-shadow: 11.35px 22.275px 25.92px 1.08px rgba(14, 62, 129, 0.29);
  box-shadow: 11.35px 22.275px 25.92px 1.08px rgba(14, 62, 129, 0.29);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  min-height: 300px;
  min-width: 300px;
}

.section .container .circles .mainCircle .title {
  font-weight: bold !important;
  color: var(--primary);
}
.section .container .circles .mainCircle .subTitle {
  font-weight: bold !important;
}

.section .container .circles .secondaryCircle {
  position: absolute;
  left: -70px;
  top: -20px;
  width: 100%;
  height: 100%;
  width: -webkit-calc(100% - 20px);
  width: calc(100% - 20px);
  height: -webkit-calc(100% - 20px);
  height: calc(100% - 20px);
  background-color: var(--primary);
  border-radius: 50%;
  overflow: hidden;
}
.section .container .circles .secondaryCircle::before {
  content: "";
  position: absolute;
  left: -25px;
  bottom: -65px;
  width: 220px;
  height: 220px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.1);
  -webkit-transition: all 0.25s;
  -o-transition: all 0.25s;
  transition: all 0.25s;
}
.section .container .circles .secondaryCircle::after {
  content: "";
  position: absolute;
  left: 30px;
  bottom: -50px;
  width: 220px;
  height: 220px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.15);
  -webkit-transition: all 0.25s;
  -o-transition: all 0.25s;
  transition: all 0.25s;
}

@media (max-width: 960px) {
  .section {
    width: 100%;
    margin: 50px 0;
  }
  .section .container {
    width: 100%;
    justify-content: center;
  }
  .section .container .circles::after {
    left: -50px;
    top: 100%;
    width: 70px;
    height: 70px;
  }
  .section .container .circles::before {
    left: -50px;
    bottom: 0;
    width: 30px;
    height: 30px;
  }
}

@media (max-width: 600px) {
  .section .container .circles .mainCircle {
    min-height: 200px;
    min-width: 200px;
  }
  .section .container .circles .secondaryCircle {
    position: absolute;
    left: 50%;
    top: -10px;
    transform: translateX(-70%);
  }
}
