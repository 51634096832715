.section {
  position: relative;
  padding: 50px 0;
  margin: 100px 0;
}

.section .container {
  position: relative;
  margin: 0 auto;
  padding: 0px 15px;
  z-index: 1;
}

.container .textContainer .bigTitle {
  font-weight: bold;
  color: var(--secondary);
  position: relative;
  margin-bottom: 20px;
}
.container .textContainer .bigTitle::after {
  position: absolute;
  content: "";
  height: 3px;
  width: 32px;
  background-color: var(--primary);
  bottom: -10px;
  margin: 0 auto 0;
  left: 95px;
}
.container .textContainer .bigTitle::before {
  position: absolute;
  content: "";
  height: 3px;
  width: 80px;
  background-color: var(--primary-light);
  bottom: -10px;
  left: 0;
}
.container .textContainer .bigSubtitle {
  color: var(--text-big-subtitle-color);
  margin: 20px 0;
}

.container .infoContainer h6 {
  margin: 10px 0;
}

.customText {
  color: var(--primary);
  font-family: "Aldrich, sans-serif";
}

@media (max-width: 960px) {
  .section {
    margin: 50px 0;
  }
  .section .container {
    width: 100%;
  }
}
@media (max-width: 600px) {
  .section {
    margin: 25px 0;
  }
}
